import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { getCountryDataList } from "countries-list";

import AddIcon from "@mui/icons-material/Add";

import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";

import { itProvinces } from "constants/countryCode";
import { emailValidation, zipCodeValidation } from "constants/regexp";

import AppModal from "components/shared/modal/AppModal";
import PhoneInput from "components/shared/phoneInput/PhoneInput";

import { postAddCustomer } from "redux/handlers/customersHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { userType } from "types/global/user";

const AddCustomer = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const allCountries = getCountryDataList();

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [clientTypeCheck, setClientTypeCheck] = useState<string>("");
 const [nationCheck, setNationCheck] = useState<string>("");

 const { control, handleSubmit, setValue, formState, reset } = useForm({
  defaultValues: {
   clientType: "",
   fiscalCode: "",
   piva: "",
   socialR: "",
   name: "",
   lastName: "",
   email: "",
   telephone: "",
   nation: "",
   region: "",
   city: "",
   zipCode: "",
   street: "",
   bornNation: ""
  }
 });

 const onSubmit: SubmitHandler<userType> = async (data: userType) => {
  setIsLoading(true);
  await dispatch(postAddCustomer(data));
  setIsLoading(false);
  setOpenModal(false);
 };

 const handleOpen = () => {
  reset();
  setClientTypeCheck("");
  setNationCheck("");
  setOpenModal(true);
 };

 const handleClose = () => {
  setClientTypeCheck("");
  setNationCheck("");
  setOpenModal(false);
 };

 return (
  <>
   <Button onClick={handleOpen} variant="kxActionButton" endIcon={<AddIcon />}>
    <FormattedMessage id="profile.addCustomer" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit(onSubmit)}
    title={intl.formatMessage({ id: "profile.addCustomer" })}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Controller
      name="clientType"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => {
         setValue("clientType", value?.code || "");
         setClientTypeCheck(value?.code || "");
        }}
        options={[
         { label: "Azienda", code: "company" },
         { label: "Privato", code: "private" }
        ]}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          InputLabelProps={{ shrink: true }}
          label={intl.formatMessage({ id: "profile.clientType" })}
         />
        )}
       />
      )}
     />
     {clientTypeCheck === "private" && (
      <Controller
       name="fiscalCode"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         label={intl.formatMessage({ id: "profile.fiscalCode" })}
         error={formState.isDirty && !!formState?.errors?.fiscalCode}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
         helperText={
          formState.isDirty &&
          !!formState?.errors?.fiscalCode &&
          intl.formatMessage({ id: "profile.fiscalCodeError" })
         }
        />
       )}
      />
     )}
     {clientTypeCheck === "company" && (
      <Controller
       name="piva"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         label={intl.formatMessage({ id: "profile.piva" })}
         error={formState.isDirty && !!formState?.errors?.piva}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
         helperText={
          formState.isDirty &&
          !!formState?.errors?.piva &&
          intl.formatMessage({ id: "profile.pivaError" })
         }
        />
       )}
      />
     )}
     {clientTypeCheck === "company" && (
      <Controller
       name="socialR"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         label={intl.formatMessage({ id: "profile.socialR" })}
         error={formState.isDirty && !!formState?.errors?.socialR}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
         helperText={
          formState.isDirty &&
          !!formState?.errors?.socialR &&
          intl.formatMessage({ id: "profile.socialRError" })
         }
        />
       )}
      />
     )}
     <Stack direction={desktopViewPort ? "row" : "column"} spacing={2}>
      <Controller
       name="name"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         label={intl.formatMessage({ id: "profile.name" })}
         error={formState.isDirty && !!formState?.errors?.name}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
         helperText={
          formState.isDirty &&
          !!formState?.errors?.name &&
          intl.formatMessage({ id: "profile.nameError" })
         }
        />
       )}
      />
      <Controller
       name="lastName"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         label={intl.formatMessage({ id: "profile.lastName" })}
         error={formState.isDirty && !!formState?.errors?.lastName}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
         helperText={
          formState.isDirty &&
          !!formState?.errors?.lastName &&
          intl.formatMessage({ id: "profile.lastNameError" })
         }
        />
       )}
      />
      <Controller
       name="email"
       control={control}
       rules={{
        required: true,
        pattern: emailValidation
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         label={intl.formatMessage({ id: "profile.email" })}
         error={formState.isDirty && !!formState?.errors?.email}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
         helperText={
          formState.isDirty &&
          !!formState?.errors?.email &&
          intl.formatMessage({ id: "profile.emailError" })
         }
        />
       )}
      />
     </Stack>
     <Stack mt={2} mb={2}>
      <Controller
       name="telephone"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <PhoneInput
         placeholder={intl.formatMessage({ id: "profile.phoneNumber" })}
         onChange={(value) => {
          setValue("telephone", value);
         }}
        />
       )}
      />
     </Stack>
     <Stack direction={desktopViewPort ? "row" : "column"} spacing={2} mt={2}>
      <Controller
       name="nation"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         sx={{ my: 2 }}
         onChange={(e, value) => {
          setValue("nation", value?.code || "");
          setNationCheck(value?.code || "");
         }}
         options={allCountries.map((country) => {
          return { label: country.name, code: country.iso2 };
         })}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           InputLabelProps={{ shrink: true }}
           label={intl.formatMessage({ id: "profile.country" })}
          />
         )}
        />
       )}
      />
      {nationCheck === "IT" && (
       <Controller
        name="region"
        control={control}
        rules={{
         required: true
        }}
        render={({ field }) => (
         <Autocomplete
          fullWidth={true}
          autoHighlight
          sx={{ my: 2 }}
          onChange={(e, value) => setValue("region", value?.code || "")}
          options={itProvinces}
          renderInput={(params) => (
           <TextField
            {...params}
            {...field}
            InputLabelProps={{ shrink: true }}
            label={intl.formatMessage({ id: "profile.region" })}
           />
          )}
         />
        )}
       />
      )}
      <Controller
       name="city"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         label={intl.formatMessage({ id: "profile.city" })}
         error={formState.isDirty && !!formState?.errors?.city}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
         helperText={
          formState.isDirty &&
          !!formState?.errors?.city &&
          intl.formatMessage({ id: "profile.cityError" })
         }
        />
       )}
      />
      {nationCheck === "IT" ? (
       <Controller
        name="zipCode"
        control={control}
        rules={{
         required: true,
         pattern: zipCodeValidation
        }}
        render={({ field }) => (
         <TextField
          {...field}
          fullWidth={true}
          label={intl.formatMessage({ id: "profile.zipCode" })}
          error={formState.isDirty && !!formState?.errors?.zipCode}
          InputLabelProps={{ shrink: true }}
          sx={{ my: 2 }}
          autoComplete="off"
          helperText={
           formState.isDirty &&
           !!formState?.errors?.zipCode &&
           intl.formatMessage({ id: "profile.zipCodeError" })
          }
         />
        )}
       />
      ) : (
       <Controller
        name="zipCode"
        control={control}
        rules={{
         required: true
        }}
        render={({ field }) => (
         <TextField
          {...field}
          fullWidth={true}
          label={intl.formatMessage({ id: "profile.zipCode" })}
          error={formState.isDirty && !!formState?.errors?.zipCode}
          InputLabelProps={{ shrink: true }}
          sx={{ my: 2 }}
          autoComplete="off"
          helperText={
           formState.isDirty &&
           !!formState?.errors?.zipCode &&
           intl.formatMessage({ id: "profile.zipCodeError" })
          }
         />
        )}
       />
      )}
      <Controller
       name="street"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         label={intl.formatMessage({ id: "profile.street" })}
         error={formState.isDirty && !!formState?.errors?.street}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         autoComplete="off"
         helperText={
          formState.isDirty &&
          !!formState?.errors?.street &&
          intl.formatMessage({ id: "profile.streetError" })
         }
        />
       )}
      />
     </Stack>
     <Controller
      name="bornNation"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => setValue("bornNation", value?.code || "")}
        options={allCountries.map((country) => {
         return { label: country.name, code: country.iso2 };
        })}
        renderInput={(params) => (
         <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          label={intl.formatMessage({ id: "profile.placeofbirth" })}
         />
        )}
       />
      )}
     />
    </form>
   </AppModal>
  </>
 );
};

export default AddCustomer;
