import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";
import { formatBytes } from "helpers/numberFormatting";

import { isNil } from "ramda";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { postEditMailboxToggles } from "redux/handlers/mailsHandler";

import MailboxReducer from "redux/reducers/mailboxReducer";

import { getMailbox } from "redux/selectors/mailboxSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IMailbox } from "types/api/mailApiInterface";

import AddMailbox from "./actions/AddMailbox";
import ChangePassword from "./actions/ChangePassword";
import DeleteMailbox from "./actions/DeleteMailbox";
import EditMailbox from "./actions/EditMailbox";

const defaultMailbox = {
 id: 0,
 isp_id: 0,
 name: "",
 email: "",
 quota: 0,
 cc: "",
 password: "",
 disableimap: false,
 disabledeliver: false,
 disablesmtp: false
};

const MailboxesContent = ({
 domain,
 mailboxes
}: {
 domain: string;
 mailboxes: Array<IMailbox>;
}): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));
 const { socket } = useContext(AppContext);
 const dispatch = useAppDispatch();

 const mailboxId = useSelector(getMailbox);

 const [selectedMailbox, setSelectedMailbox] = useState<IMailbox>(defaultMailbox);
 const [showPassword, setShowPassword] = useState<string>("password");
 const [mailboxSize, setMailboxSize] = useState<string>("0 KB");
 const [checkedSmtp, setCheckedSmtp] = useState<boolean>(false);
 const [checkedDeliver, setCheckedDeliver] = useState<boolean>(false);
 const [checkedImap, setCheckedImap] = useState<boolean>(false);

 useEffect(() => {
  if (mailboxes.length > 0) {
   const box = mailboxes.find((element) => element.id === mailboxId);
   if (box) {
    setSelectedMailbox(box);
    setCheckedDeliver(box.disabledeliver);
    setCheckedImap(box.disableimap);
    setCheckedSmtp(box.disablesmtp);
   } else {
    setSelectedMailbox(mailboxes[0]);
    setCheckedDeliver(mailboxes[0].disabledeliver);
    setCheckedImap(mailboxes[0].disableimap);
    setCheckedSmtp(mailboxes[0].disablesmtp);
   }
  }
 }, []);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("mailbox_logs", async (value): Promise<void> => {
    for (let element of value) {
     if (mailboxes.find((box) => box.email === element.mailbox)) {
      updateMailboxSize(element.mailbox, element.size);
     }
    }
   });
  return () => {
   !isNil(socket) && socket.off("mailbox_logs");
  };
 }, [socket]);

 const parseSizing = (value: number, type: string): string => {
  if (type && type.includes("M")) {
   return `${value} MB`;
  } else if (type && type.includes("G")) {
   return `${value} GB`;
  } else {
   return `${value} KB`;
  }
 };

 const updateMailboxSize = (box: string, size: string) => {
  if (box === selectedMailbox.email) {
   setMailboxSize(parseSizing(parseInt(size), size));
  }
 };

 const handleClickShowPassword = () =>
  setShowPassword(showPassword === "password" ? "text" : "password");

 const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
  event.preventDefault();
 };

 const handleSwitchToggle = async (value: boolean, type: string) => {
  switch (type) {
   case "deliver":
    setCheckedDeliver(value);
    await dispatch(
     postEditMailboxToggles(
      selectedMailbox.id,
      selectedMailbox.isp_id,
      value,
      selectedMailbox.disableimap,
      selectedMailbox.disablesmtp,
      domain || ""
     )
    );
    break;
   case "sending":
    setCheckedSmtp(value);
    await dispatch(
     postEditMailboxToggles(
      selectedMailbox.id,
      selectedMailbox.isp_id,
      selectedMailbox.disabledeliver,
      selectedMailbox.disableimap,
      value,
      domain || ""
     )
    );
    break;
   case "imap":
    setCheckedImap(value);
    await dispatch(
     postEditMailboxToggles(
      selectedMailbox.id,
      selectedMailbox.isp_id,
      selectedMailbox.disabledeliver,
      value,
      selectedMailbox.disablesmtp,
      domain || ""
     )
    );
    break;
   default:
    break;
  }
 };

 const handleChangeMailbox = (box: IMailbox) => {
  dispatch(MailboxReducer.actions.setMailbox(box.id));
  setSelectedMailbox(box);
  setCheckedDeliver(box.disabledeliver);
  setCheckedImap(box.disableimap);
  setCheckedSmtp(box.disablesmtp);
 };

 return (
  <>
   <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    spacing={2}
    py={0.5}
    pt={2}
    pr={2}>
    <Stack direction="row" alignItems="center" textAlign="start" spacing={2} width={"100%"}>
     <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
      <FormattedMessage id="mails.mailboxName" />
     </Typography>
     <Autocomplete
      autoHighlight
      size="small"
      fullWidth={!desktopViewPort}
      sx={{ my: 2, width: "100%" }}
      onChange={(e, value) =>
       !isNil(value)
        ? handleChangeMailbox(
           mailboxes.find((mailbox) => mailbox.id === value?.id) || defaultMailbox
          )
        : handleChangeMailbox(mailboxes[0])
      }
      options={mailboxes.map((mailbox) => {
       return { label: mailbox.email, id: mailbox.id };
      })}
      value={{
       label: mailboxes.find((mailbox) => mailbox.id === selectedMailbox.id)?.email,
       id: mailboxes.find((mailbox) => mailbox.id === selectedMailbox.id)?.id
      }}
      renderInput={(params) => (
       <TextField {...params} fullWidth={true} InputLabelProps={{ shrink: true }} />
      )}
     />
    </Stack>
   </Stack>
   <Divider />
   <Stack direction="row" alignItems="center" textAlign="start" spacing={2} py={0.5} pr={2}>
    <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
     <FormattedMessage id="sites.password" />
    </Typography>
    {selectedMailbox.password ? (
     <TextField
      fullWidth
      type={showPassword}
      autoComplete="current-password"
      value={selectedMailbox.password}
      InputProps={{
       readOnly: true,
       endAdornment: (
        <InputAdornment position="end">
         <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end">
          {showPassword === "text" ? <VisibilityOff /> : <Visibility />}
         </IconButton>
        </InputAdornment>
       )
      }}
      variant="standard"
     />
    ) : (
     <Typography noWrap>{"Not provided"}</Typography>
    )}
   </Stack>
   <Divider />
   <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    spacing={2}
    py={0.5}
    pr={2}>
    <Stack direction="row" alignItems="center" textAlign="start" spacing={2}>
     <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
      <FormattedMessage id="mails.mailboxesQuota" />
     </Typography>
     <Typography variant="body1">
      {selectedMailbox.quota === -1 ? (
       <FormattedMessage id="mails.quotaUnlimited" />
      ) : (
       `${formatBytes(selectedMailbox.quota, "MB")} MB`
      )}
     </Typography>
    </Stack>
   </Stack>
   <Divider />
   <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    spacing={2}
    py={0.5}
    pr={2}>
    <Stack direction="row" alignItems="center" textAlign="start" spacing={2}>
     <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
      <FormattedMessage id="mails.mailboxesSize" />
     </Typography>
     <Typography variant="body1">{`${mailboxSize}`}</Typography>
    </Stack>
   </Stack>
   <Divider />
   <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    spacing={2}
    py={0.5}
    pr={2}>
    <Stack direction="row" alignItems="center" textAlign="start" spacing={2} width={"100%"}>
     <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
      <FormattedMessage id="mails.mailboxesDescription" />
     </Typography>
     <Typography variant="body1" noWrap width={"50%"}>
      {selectedMailbox.name || <FormattedMessage id="mails.mailboxesDesctiptionMissing" />}
     </Typography>
    </Stack>
   </Stack>
   <Divider />
   <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    spacing={2}
    py={0.5}
    pr={2}>
    <Stack direction="row" alignItems="center" textAlign="start" spacing={2} width={"100%"}>
     <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
      <FormattedMessage id="mails.mailboxesCc" />
     </Typography>
     <Typography variant="body1" noWrap width={"50%"}>
      {selectedMailbox.cc || <FormattedMessage id="mails.mailboxesCsMissing" />}
     </Typography>
    </Stack>
   </Stack>
   <Divider />
   <Stack direction="row" alignItems="center" textAlign="start" py={0.5} pr={2}>
    <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
     <FormattedMessage id="mails.mailboxDeliver" />
    </Typography>
    <Switch
     checked={checkedDeliver}
     onChange={(event) => handleSwitchToggle(event.target.checked, "deliver")}
    />
   </Stack>
   <Divider />
   <Stack direction="row" alignItems="center" textAlign="start" py={0.5} pr={2}>
    <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
     <FormattedMessage id="mails.mailboxSending" />
    </Typography>
    <Switch
     checked={checkedSmtp}
     onChange={(event) => handleSwitchToggle(event.target.checked, "sending")}
    />
   </Stack>
   <Divider />
   <Stack direction="row" alignItems="center" textAlign="start" py={0.5} pr={2}>
    <Typography fontWeight="bold" variant="subtitle1" px={2} minWidth={150}>
     <FormattedMessage id="mails.mailboxImap" />
    </Typography>
    <Switch
     checked={checkedImap}
     onChange={(event) => handleSwitchToggle(event.target.checked, "imap")}
    />
   </Stack>
   <Divider />
   <Stack direction="row" alignItems="center" p={1} justifyContent="center">
    <Stack direction={"row"} alignItems="center" spacing={3} maxWidth={"100%"}>
     <AddMailbox type="icon" />
     <EditMailbox selectedMailbox={selectedMailbox} mailDomain={domain || ""} />
     <DeleteMailbox id={selectedMailbox.id} />
     <ChangePassword mailDomain={domain || ""} mailboxData={selectedMailbox} />
    </Stack>
   </Stack>
  </>
 );
};

export default MailboxesContent;
