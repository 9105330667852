import { createSelector } from "@reduxjs/toolkit";

import { isNil } from "ramda";

import { IProfile } from "types/redux/userInterfaces";

import { AppState } from "../store";

const getUser = (state: AppState) => state.user;

export const getUserAuthenticated = createSelector(
 getUser,
 (user): boolean => !!user?.authenticated
);

export const getIsAdmin = createSelector(getUser, (user): boolean => user?.isAdmin || false);

export const getUserPersonalData = createSelector(
 getUser,
 (user): { firstname: string; lastname: string; avatar: string | null; company: string } => ({
  firstname: user?.firstname || "firstname",
  lastname: user?.lastname || "lastname",
  avatar: user?.propic || null,
  company: user?.companyName || ""
 })
);
export const getUserBalance = createSelector(getUser, (user): number => user?.balance || 0);
export const getUserBonus = createSelector(getUser, (user): number => user?.bonus || 0);
export const getUserCash = createSelector(getUser, (user): number => user?.cash || 0);
export const getUserAvatar = createSelector(getUser, (user): string => user?.propic || "");
export const getUserTax = createSelector(getUser, (user): number => user?.tax || 0);
export const getUserId = createSelector(getUser, (user): number => user?.id || 0);
export const getUserIdString = createSelector(getUser, (user): string => user?.userid || "");
export const getTelegramId = createSelector(getUser, (user): string => user?.telegramID || "");
export const getUserContract = createSelector(
 getUser,
 (user): boolean => user?.contract_accepted || false
);
export const getUserLanguage = createSelector(getUser, (user): string => {
 const storedLanguage = localStorage.getItem("language");
 return user && !isNil(user?.language) ? user.language : storedLanguage || "it";
});
export const getUserProfileData = createSelector(
 getUser,
 (user): IProfile => ({
  firstname: user?.firstname || "",
  lastname: user?.lastname || "",
  companyName: user?.companyName || "",
  email: user?.email || "",
  phone: user?.telephone || null,
  street: user?.address || null,
  city: user?.city || null,
  country: user?.country || null,
  zipCode: user?.zip || null,
  vatCode: user?.partitaIVA || null,
  sdi: user?.sdi || null,
  pec: user?.pec || null
 })
);
