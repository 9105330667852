import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { postChangeRootLogin } from "redux/handlers/serverHandler";

import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

const ServicePermit = (): ReactElement => {
 const dispatch = useAppDispatch();
 const { socket } = useContext(AppContext);

 const server = useSelector(getServerDetails);

 const [status, setStatus] = useState<string>("no");
 const [checked, setChecked] = useState<boolean>(false);
 const [pageLoaded, setPageLoaded] = useState<boolean>(false);

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("server_logs", (value): void => {
    if (value?.length > 0) {
     for (let i = 0; i < value.length; i++) {
      if (server.agentoken === value[i]?.token) {
       setStatus(value[i]?.ser_ssh || "no");
       setChecked(value[i]?.ser_sshen === "yes");
       setPageLoaded(true);
      }
     }
    }
   });
  }
  return () => {
   !isNil(socket) && socket.off("server_logs");
  };
 }, [socket, server]);

 const handleChangePermit = async (value: boolean) => {
  await dispatch(postChangeRootLogin(server?.id || 0, value));
 };

 return pageLoaded ? (
  <FormControlLabel
   disabled={status === "no"}
   value="top"
   control={
    <Switch
     color="primary"
     defaultChecked={checked}
     onChange={(e, value) => handleChangePermit(value)}
    />
   }
   label="PermitRootLogin"
   labelPlacement="start"
  />
 ) : (
  <></>
 );
};

export default ServicePermit;
