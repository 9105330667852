import {
 serverOrderStatus,
 serverOrderStatusContainer,
 serverOrderStatusGateway,
 serverOrderStatusWebMailNs
} from "constants/server";

import { IDockerOrderSelected } from "types/api/dockerApiInterface";
import { IGatewayOrderSelected } from "types/api/gatewayApiInterface";
import { IJobsData } from "types/api/hetznerJobs";
import { IJobQueueSelected } from "types/api/jobqueueApiInterface";
import { IServerTypes, IServerTypesApi } from "types/api/serversApiInterface";
import { linuxOs } from "types/global/server";

export const divideServerTypes = (
 upgradesList: Array<IServerTypesApi>,
 type: string,
 rescale: boolean,
 serverDisk: number,
 serverType: string
): Array<IServerTypesApi> => {
 return upgradesList.filter((upgrade) => {
  if (type === "dedicated") {
   if (rescale)
    return (
     upgrade.name.includes("VFC") &&
     upgrade.disk >= serverDisk &&
     (!upgrade.name.includes(serverType.toUpperCase()) ||
      (upgrade.name.includes(serverType.toUpperCase()) && upgrade.disk > serverDisk))
    );
   else
    return (
     upgrade.name.includes("VFC") &&
     upgrade.disk >= serverDisk &&
     !upgrade.name.includes(serverType.toUpperCase())
    );
  } else {
   if (rescale)
    return (
     !upgrade.name.includes("VFC") &&
     upgrade.disk >= serverDisk &&
     (!upgrade.name.includes(serverType.toUpperCase()) ||
      (upgrade.name.includes(serverType.toUpperCase()) && upgrade.disk > serverDisk))
    );
   else
    return (
     !upgrade.name.includes("VFC") &&
     upgrade.disk >= serverDisk &&
     !upgrade.name.includes(serverType.toUpperCase())
    );
  }
 });
};

export const formatServerPrice = (
 typesList: Array<IServerTypes>,
 enabledIpv4: boolean,
 enabledBackup: boolean,
 selectedType: number
): number => {
 const filteredList = typesList.find((element) => element.id === selectedType);
 return (
  Number(filteredList?.server_type_price) +
  (enabledIpv4 ? Number(filteredList?.ipv4_total) : 0) +
  (enabledBackup ? Number(filteredList?.backup_total) : 0)
 );
};

export const checkServerOs = (os: string): "windows" | "linux" => {
 if (linuxOs.includes(os)) {
  return "linux";
 } else if (os === "windows") {
  return "windows";
 } else {
  return "linux";
 }
};

export const getJobInProgress = (
 jobs:
  | Array<IJobsData>
  | Array<IJobQueueSelected>
  | Array<IGatewayOrderSelected>
  | Array<IDockerOrderSelected>
): number => {
 let count = 0;
 for (let job of jobs) {
  if (job.status !== "success" && job.status !== "failed") {
   count++;
  }
 }
 return count;
};

export const renderOrderPercent = (status: string, type: string): number => {
 if (type.includes("webserver") || type.includes("nameserver") || type.includes("mailserver")) {
  const percent = 100 / (serverOrderStatus.length + serverOrderStatusWebMailNs.length);
  if (serverOrderStatus.includes(status)) {
   return Number(((serverOrderStatus.indexOf(status) + 1) * percent).toFixed(0));
  } else if (serverOrderStatusWebMailNs.includes(status)) {
   return Number(
    ((serverOrderStatus.indexOf(status) + 1 + serverOrderStatus.length) * percent).toFixed(0)
   );
  } else {
   return percent;
  }
 } else if (type.includes("container")) {
  const percent = 100 / (serverOrderStatus.length + serverOrderStatusContainer.length);
  if (serverOrderStatus.includes(status)) {
   return Number(((serverOrderStatus.indexOf(status) + 1) * percent).toFixed(0));
  } else if (serverOrderStatusContainer.includes(status)) {
   return Number(
    ((serverOrderStatus.indexOf(status) + 1 + serverOrderStatus.length) * percent).toFixed(0)
   );
  } else {
   return percent;
  }
 } else if (type.includes("gateway")) {
  const percent = 100 / (serverOrderStatus.length + serverOrderStatusGateway.length);
  if (serverOrderStatus.includes(status)) {
   return Number(((serverOrderStatus.indexOf(status) + 1) * percent).toFixed(0));
  } else if (serverOrderStatusContainer.includes(status)) {
   return Number(
    ((serverOrderStatus.indexOf(status) + 1 + serverOrderStatus.length) * percent).toFixed(0)
   );
  } else {
   return percent;
  }
 } else {
  const percent = 100 / serverOrderStatus.length;
  if (serverOrderStatus.includes(status)) {
   return Number(((serverOrderStatus.indexOf(status) + 1) * percent).toFixed(0));
  } else {
   return percent;
  }
 }
};
