import React from "react";
import { useNavigate } from "react-router-dom";

import CancelIcon from "@mui/icons-material/Cancel";

import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import MailPerformance from "components/mails/MailPerformance";
import MailsMenu from "components/mails/MailsMenu";
import IconSelector from "components/shared/images/IconSelector";

import { postStatusMailDomain } from "redux/handlers/mailsHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { ISpamSelector } from "types/api/mailApiInterface";
import { mailsTableData } from "types/global/table";

const MailsTable = ({
 rowData,
 index,
 spamfilter,
 performance
}: {
 rowData: mailsTableData;
 index: number;
 spamfilter: Array<ISpamSelector>;
 performance: Array<{ domain: string; disk: string }>;
}) => {
 const dispatch = useAppDispatch();
 const navigate = useNavigate();

 const handleRedirectToMailDetails = (id: number, domain: string, active: boolean) => {
  active && navigate(`/app/mail/${id}/details`, { state: { key: domain } });
 };

 const handleSwitchToggle = async (isp_Id: number, id: number) => {
  await dispatch(postStatusMailDomain(id, isp_Id));
 };

 return (
  <TableRow
   key={`server-list-${index}`}
   sx={{ cursor: "pointer" }}
   onClick={() => handleRedirectToMailDetails(rowData.id, rowData.domain, rowData.active)}>
   <TableCell component="th" scope="row">
    <Typography variant="small" fontWeight="bold">
     {rowData.active === true ? (
      <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
     ) : (
      <CancelIcon style={{ color: "red" }} />
     )}
    </Typography>
   </TableCell>
   <TableCell align="right" component="th" scope="row">
    <Stack direction="row" alignItems="center" justifyContent="left" spacing={3}>
     <Typography variant="small">{rowData.domain}</Typography>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack direction="row" justifyContent="center" spacing={0.5}>
     <Typography variant="small">{rowData.mailbox.length}</Typography>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack direction="row" justifyContent="center" spacing={0.5}>
     <Typography variant="small">
      {spamfilter.find((element) => element.policy_id === rowData.policy_id)?.policy_name ||
       "-spamfilter disabled-"}
     </Typography>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack
     direction="row"
     justifyContent="center"
     spacing={0}
     onClick={(event) => event.stopPropagation()}>
     <Switch
      checked={rowData.active}
      onClick={() => handleSwitchToggle(rowData.isp_id, rowData.id)}
     />
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <MailPerformance domain={rowData.domain} performance={performance} />
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack
     direction="row"
     alignItems="center"
     justifyContent="center"
     spacing={0.5}
     onClick={(event) => event.stopPropagation()}>
     <MailsMenu isp_id={rowData.isp_id} maildomain={rowData.domain} id={rowData.id} />
    </Stack>
   </TableCell>
  </TableRow>
 );
};

export default MailsTable;
