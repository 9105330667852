import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { windowsDownloadLink } from "constants/agent";

import AppModal from "components/shared/modal/AppModal";

import { postSetupAgent, postSetWindowsToken } from "redux/handlers/serverHandler";

import { getServerDetails } from "redux/selectors/serversSelector";
import { getUserId } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

const AgentInstaller = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const { system_family } = useSelector(getServerDetails);
 const { server_id } = useSelector(getServerDetails);
 const { id } = useSelector(getServerDetails);
 const userid = useSelector(getUserId);

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [agentToken, setAgentToken] = useState<string>("");
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  if (system_family === "windows") {
   await dispatch(postSetWindowsToken(id || 0, agentToken));
  } else {
   await dispatch(postSetupAgent(server_id || 0, userid));
  }

  setLoading(false);
  handleClose();
 };

 const RenderContent = (): ReactElement => {
  switch (system_family) {
   case "windows":
    return (
     <Stack spacing={2}>
      <Alert severity="info">
       <Typography>
        <FormattedMessage id={`agent.${system_family}Install`} />
       </Typography>
       <Typography>
        <FormattedMessage
         id="agent.windowslink"
         values={{
          link: (
           <a href={windowsDownloadLink} target="_blank" rel="noreferrer">
            {windowsDownloadLink}
           </a>
          )
         }}
        />
       </Typography>
      </Alert>
      <TextField
       label={intl.formatMessage({ id: "agent.agentoken" })}
       fullWidth
       value={agentToken}
       onChange={(e) => setAgentToken(e.target.value)}
      />
     </Stack>
    );
   default:
    return <FormattedMessage id={`agent.${system_family}Install`} />;
  }
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <FormattedMessage id="agent.installAgent" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || (system_family === "windows" && agentToken === "")}
    title={intl.formatMessage({ id: "agent.installAgent" })}>
    <RenderContent />
   </AppModal>
  </>
 );
};

export default AgentInstaller;
