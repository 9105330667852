export const steps = [
 {
  label: "mails.add.selectName",
  description: "mails.add.selectNameMessage"
 },
 {
  label: "mails.add.host",
  description: "mails.add.hostMessage"
 },
 {
  label: "mails.add.spam",
  description: "mails.add.spamMessage"
 },
 {
  label: "mails.add.dkim",
  description: "mails.add.dkimMessage"
 }
];
