import React, { ReactElement } from "react";

import MenuItem from "@mui/material/MenuItem";

import DeleteTeamsUser from "./actions/DeleteTeamsUser";
import UpdateTeamsPassword from "./actions/UpdateTeamsPassword";
import UpdateTeamsUser from "./actions/UpdateTeamsUser";

const TeamsActions = ({ id, user }: { id: number; user?: string }): ReactElement => {
 return (
  <>
   <MenuItem>
    <UpdateTeamsUser id={id} userMail={user || ""} />
   </MenuItem>
   <MenuItem>
    <UpdateTeamsPassword id={id} />
   </MenuItem>
   <MenuItem>
    <DeleteTeamsUser id={id} />
   </MenuItem>
  </>
 );
};

export default TeamsActions;
