import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getRebuildOs, postRebuildServer } from "redux/handlers/serverHandler";

import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IOsDetails } from "types/api/serversApiInterface";

const ServerRebuild = (): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const dispatch = useAppDispatch();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const { id } = useSelector(getServerDetails);
 const { locked } = useSelector(getServerDetails);

 const [osList, setOsList] = useState<Array<IOsDetails>>([]);
 const [osSelected, setOsSelected] = useState<number>(0);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postRebuildServer(osSelected, id || 0));
  setLoading(false);
  setOpenModal(false);
 };

 useEffect(() => {
  (async () => {
   const data = await dispatch(getRebuildOs());
   setOsList(isNil(data) ? [] : data);
  })();
 }, []);

 return (
  <>
   <Paper elevation={0} sx={{ mt: 2, borderRadius: "10px", boxShadow: 0 }}>
    <Stack p={2} spacing={2}>
     <Stack direction="row" spacing={1}>
      <IconSelector icon="BuildIcon" props={{ fontSize: "medium", color: "secondary" }} />
      <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
       <FormattedMessage id="server.rebuild" />
      </Typography>
     </Stack>
     <Alert severity="warning" variant="outlined">
      <FormattedMessage id="server.rebuild.warningMessage" />
     </Alert>
     <Stack direction={desktopViewPort ? "row" : "column"} spacing={2}>
      <Autocomplete
       disablePortal
       fullWidth
       id="rebuild-box-os"
       options={osList.map((element) => {
        return { label: element.name, id: element.id };
       })}
       onChange={(e, value) => {
        setOsSelected(isNil(value) ? 0 : value.id);
       }}
       sx={{ width: 300 }}
       renderInput={(params) => (
        <TextField {...params} label={intl.formatMessage({ id: "server.rebuild.osLabel" })} />
       )}
      />
      <Button variant="kxActionButton" onClick={handleOpen}>
       <FormattedMessage id="server.rebuild.confirmRebuild" />
      </Button>
      <AppModal
       open={openModal}
       close={handleClose}
       title={intl.formatMessage({ id: "server.rebuild.modalTitle" })}
       handleClose={handleClose}
       handleConfirm={handleConfirm}
       disabled={loading || osSelected === 0 || locked}>
       <Stack>
        {locked ? (
         <Alert severity="error">
          <FormattedMessage id="server.rebuild.protectionActiveError" />
         </Alert>
        ) : (
         <Typography>
          <FormattedMessage id="server.rebuild.modalMessage" />
         </Typography>
        )}
       </Stack>
      </AppModal>
     </Stack>
    </Stack>
   </Paper>
  </>
 );
};

export default ServerRebuild;
