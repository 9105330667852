import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux/es/exports";

import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postDeleteTeamsUser } from "redux/handlers/teamsHandler";

import { getUserId } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

const DeleteTeamsUser = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const userId: number = useSelector(getUserId);

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleSubmit = async () => {
  setIsLoading(true);
  await dispatch(postDeleteTeamsUser(id, userId));
  setIsLoading(false);
  setOpenModal(false);
 };

 return (
  <>
   <Typography onClick={handleOpen}>
    <FormattedMessage id="teams.deleteTeamsUser" />
   </Typography>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit}
    title={intl.formatMessage({ id: "teams.deleteTeamsUser" })}>
    <FormattedMessage id="teams.deleteTeamsUserMessage" />
   </AppModal>
  </>
 );
};

export default DeleteTeamsUser;
