import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formatIpRange } from "helpers/addons";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";

import { getAllServersNotPaged } from "redux/handlers/serverHandler";

import { getNetworksDataset } from "redux/selectors/networksSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IAllServers } from "types/api/serversApiInterface";

import AttachServerToSubnet from "../actions/AttachServerToSubnet";
import CreateSubnet from "../actions/CreateSubnet";
import DeleteSubnet from "../actions/DeleteSubnet";
import DetachServerToSubnet from "../actions/DetachServerToSubnet";

const NetworkSubnet = (): ReactElement => {
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));
 const params = useParams<{ id: string; section: string }>();

 const networkData = useSelector(getNetworksDataset).find(
  (element) => element.id === Number(params.id)
 );

 const [servers, setServers] = useState<Array<IAllServers>>([]);
 const [loading, setLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   setLoading(true);
   setServers(await dispatch(getAllServersNotPaged()));
   setLoading(false);
  })();
 }, []);

 return (
  <Paper elevation={0} sx={{ mt: 1, borderRadius: "10px", boxShadow: 0 }}>
   <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} py={1}>
    <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
    <Typography variant="h6" component="h2" ml={1}>
     <FormattedMessage id="addons.network.subnet" />{" "}
    </Typography>
   </Stack>
   {loading ? (
    <CircularProgress />
   ) : (
    <Stack p={2}>
     {networkData?.subnets?.length === 0 ? (
      <Alert severity="info">
       <Stack spacing={1} alignItems="flex-start">
        <Typography>
         <FormattedMessage id="addons.network.noSubnetFound" />
        </Typography>
        <CreateSubnet id={networkData?.id || 0} ip={formatIpRange(networkData?.ip_range || "")} />
       </Stack>
      </Alert>
     ) : (
      networkData?.subnets.map((element, index) => {
       return (
        <Grid key={`subnet-index-${index}`} container>
         <Grid xs={desktopViewPort ? 6 : 12}>
          <Stack direction="row" alignItems="center">
           <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={2}
            width="40%"
            sx={{
             borderRadius: 2,
             border: "2px solid lightgrey"
            }}>
            <Typography>{element.ip_range}</Typography>
            <DeleteSubnet id={networkData.id || 0} subnet_id={element.id} />
           </Stack>
           <Stack width="60%">
            <LinearProgress
             sx={{ textAlign: "start" }}
             color="success"
             variant="buffer"
             value={0}
             valueBuffer={0}
            />
           </Stack>
          </Stack>
         </Grid>
         <Grid xs={desktopViewPort ? 6 : 12}>
          <Stack p={1} sx={{ borderRadius: 2, border: "2px solid lightgrey" }}>
           {networkData.servers.length > 0 ? (
            <Stack spacing={1}>
             {networkData.servers.map((element, index) => {
              return (
               <Stack key={`subnet-index-${index}`} direction="row" alignItems="center">
                <Stack
                 direction="row"
                 justifyContent="space-between"
                 alignItems="center"
                 px={2}
                 width="100%"
                 sx={{
                  borderRadius: 2,
                  border: "2px solid lightgray",
                  px: 2,
                  py: 1
                 }}>
                 <Stack alignItems={"flex-start"} spacing={1}>
                  <Typography variant="subtitle2">
                   {servers.find((serv) => serv.server_id === element)?.server_name}
                  </Typography>
                  <Typography variant="caption">
                   {
                    servers
                     .find((serv) => serv.server_id === element)
                     ?.private_net.find((net) => net.network === networkData.network_id)?.ip
                   }
                  </Typography>
                 </Stack>
                 <DetachServerToSubnet
                  id={servers.find((serv) => serv.server_id === element)?.id || 0}
                  network={networkData.network_id}
                 />
                </Stack>
               </Stack>
              );
             })}
             <AttachServerToSubnet id={networkData.id || 0} servers={servers} />
            </Stack>
           ) : (
            <Alert severity="info">
             <Stack>
              <Typography>
               <FormattedMessage id="addons.network.noServerAttachedToSubnet" />
              </Typography>
              <AttachServerToSubnet id={networkData.id || 0} servers={servers} />
             </Stack>
            </Alert>
           )}
          </Stack>
         </Grid>
        </Grid>
       );
      })
     )}
    </Stack>
   )}
  </Paper>
 );
};

export default NetworkSubnet;
