import React, { ReactElement } from "react";
import { FormattedDate } from "react-intl";
import { useNavigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import ChangeProtection from "components/addons/actions/ChangeProtection";
import NetworkActions from "components/addons/networks/NetworkActions";
import AdminLogAsUser from "components/shared/admin/AdminLogAsUser";
import IconSelector from "components/shared/images/IconSelector";

import { networksTableData } from "types/global/table";

const NetworksTable = ({
 rowData,
 index
}: {
 rowData: networksTableData;
 index: number;
}): ReactElement => {
 const navigate = useNavigate();

 const RenderStatus = ({ status }: { status: string }) => {
  switch (status) {
   case "active":
    return (
     <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
    );
   default:
    return (
     <IconSelector icon="StatusIcon" props={{ style: { color: "red" }, fontSize: "small" }} />
    );
  }
 };

 const handleNavigate = (id: number) => {
  rowData.status === "active" &&
   navigate(`/addons/network/manage/${id}/details`, { state: { cloudbox: rowData.name } });
 };

 return (
  <TableRow key={`volumes-list-${index}`} onClick={() => handleNavigate(rowData.id)}>
   <TableCell component="th" scope="row">
    <Stack direction="column">
     <Stack direction="row" alignItems="center" spacing={1}>
      <RenderStatus status={rowData.status} />
      <Typography variant="inherit" fontWeight="bold">
       {rowData.name}
      </Typography>
     </Stack>
     <Stack onClick={(event) => event.stopPropagation()} sx={{ cursor: "pointer" }}>
      <AdminLogAsUser userid={rowData.userid || ""} owner={rowData.personal_name || ""} />
     </Stack>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Typography variant="inherit">{rowData.ip_range}</Typography>
   </TableCell>
   <TableCell onClick={(e) => e.stopPropagation()}>
    <ChangeProtection id={rowData.id} protection={rowData.protection} type="networks" />
   </TableCell>
   <TableCell>
    <FormattedDate value={rowData.expire} />
   </TableCell>
   <TableCell onClick={(e) => e.stopPropagation()}>
    <NetworkActions rowData={rowData} />
   </TableCell>
  </TableRow>
 );
};

export default NetworksTable;
