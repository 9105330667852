import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import EmptyList from "components/shared/emptyList/EmptyList";
import IconSelector from "components/shared/images/IconSelector";

import { getCertificates } from "redux/handlers/addonsHandle";

import { useAppDispatch } from "hooks/reduxHook";

import { ICertificateAPI } from "types/api/certificatesApiInterface";

import CreateCertificate from "./actions/CreateCertificate";
import DeleteCertificate from "./actions/DeleteCertificate";
import UpdateCertificate from "./actions/UpdateCertificate";

const LoadBalancerCertificates = (): ReactElement => {
 const dispatch = useAppDispatch();
 const { socket } = useContext(AppContext);

 const [reloadData, setReloadData] = useState<boolean>(true);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [certificates, setCertificates] = useState<ICertificateAPI>({ dataset: [], totalCount: 0 });

 useEffect(() => {
  !isNil(socket) &&
   socket.on("addons_data", (value): void => {
    if (value?.completed && value?.section === "certificates") {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("addons_data");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    setCertificates(await dispatch(getCertificates()));
    setReloadData(false);
    if (isLoading) {
     setIsLoading(false);
    }
   }
  })();
 }, [reloadData]);

 const checkStatus = (expireDate: string): boolean => {
  const today = new Date().getTime();
  const expire = new Date(expireDate).getTime();
  return today < expire;
 };

 return isLoading ? (
  <Paper elevation={0} sx={{ mt: 1, borderRadius: "10px", boxShadow: 0 }}>
   <CircularProgress />
  </Paper>
 ) : certificates.dataset.length > 0 ? (
  <Paper elevation={0} sx={{ mt: 1, borderRadius: "10px", boxShadow: 0 }}>
   <Stack direction="row" justifyContent="space-between" alignItems="center">
    <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} py={1}>
     <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
     <Typography variant="h6" component="h2" ml={1}>
      <FormattedMessage id="addons.loadBalancer.certificates" />{" "}
     </Typography>
    </Stack>
    <CreateCertificate />
   </Stack>
   <TableContainer component="div">
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
     <TableHead>
      <TableRow>
       <TableCell>
        <FormattedMessage id="addons.certificates.name" />
       </TableCell>
       <TableCell>
        <FormattedMessage id="addons.certificates.valid" />
       </TableCell>
       <TableCell>
        <FormattedMessage id="addons.certificates.domains" />
       </TableCell>
       <TableCell align="right">
        <FormattedMessage id="app.actions" />
       </TableCell>
      </TableRow>
     </TableHead>
     <TableBody>
      {certificates.dataset.map((row, index) => (
       <TableRow key={`isp-config-list-${index}`}>
        <TableCell component="th" scope="row">
         <Stack direction="row" alignItems="center" spacing={1}>
          <IconSelector
           icon={checkStatus(row.not_valid_after) ? "StatusIcon" : "ErrorIcon"}
           props={{
            fontSize: "medium",
            color: checkStatus(row.not_valid_after) ? "success" : "error"
           }}
          />
          <Stack>
           <Typography variant="caption">{row.name}</Typography>
           {!checkStatus(row.not_valid_after) && (
            <Typography color="error" variant="caption">
             <FormattedMessage id="app.expired" />
            </Typography>
           )}
          </Stack>
         </Stack>
        </TableCell>
        <TableCell component="th" scope="row">
         <Stack direction="row">
          <Typography variant="caption" fontWeight="bold" sx={{ minWidth: 30 }}>
           <FormattedMessage id="app.from" />
          </Typography>
          <Typography variant="caption">
           <FormattedDate value={row.not_valid_before} />
          </Typography>
         </Stack>
         <Stack direction="row">
          <Typography variant="caption" fontWeight="bold" sx={{ minWidth: 30 }}>
           <FormattedMessage id="app.to" />
          </Typography>
          <Typography variant="caption">
           <FormattedDate value={row.not_valid_after} />
          </Typography>
         </Stack>
        </TableCell>
        <TableCell component="th" scope="row">
         <Stack>
          {row.domain_names.map((element, index) => {
           return (
            <Typography variant="caption" key={`domain-index-${index}`}>
             {element}
            </Typography>
           );
          })}
         </Stack>
        </TableCell>
        <TableCell align="right">
         <Stack direction="row" justifyContent="flex-end">
          <UpdateCertificate id={row.id} oldName={row.name} />
          <DeleteCertificate id={row.id} />
         </Stack>
        </TableCell>
       </TableRow>
      ))}
     </TableBody>
    </Table>
   </TableContainer>
  </Paper>
 ) : (
  <Paper elevation={0} sx={{ mt: 1, borderRadius: "10px", boxShadow: 0 }}>
   <EmptyList />
  </Paper>
 );
};

export default LoadBalancerCertificates;
