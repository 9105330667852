import React, { Fragment, ReactElement, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import useDebounce from "react-use/lib/useDebounce";
import { formatPort, separatePorts } from "helpers/docker";

import SearchIcon from "@mui/icons-material/Search";

import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import EmptyList from "components/shared/emptyList/EmptyList";

import { getDockerProxy } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IContainerData } from "types/api/dockerApiInterface";

import DockerWebsite from "../website/DockerWebsite";

import AddProxyContainer from "./actions/AddProxyContainer";
import DeleteProxyContainer from "./actions/DeleteProxyContainer";

const DockerProxy = ({
 id,
 reloadData,
 resetReload
}: {
 id: number;
 reloadData: boolean;
 resetReload: () => void;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const searchRef = useRef<HTMLInputElement>(null);

 const [container, setContainers] = useState<Array<IContainerData>>([]);
 const [containerNumber, setContainersNumber] = useState<number>(0);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [listWasEmpty, setListWasEmpty] = useState<boolean>(false);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [open, setOpen] = useState<number>(-1);

 useEffect(() => {
  (async () => {
   const containerList = await dispatch(getDockerProxy(id, 0, 10));
   if (containerList.dataset.length === 0) setListWasEmpty(true);
   setContainers(containerList.dataset);
   setContainersNumber(containerList.totalCount);
   setIsLoading(false);
  })();
 }, []);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    const containerList = await dispatch(
     getDockerProxy(id, currentPage, rowPerPage, searchRef?.current?.value)
    );
    setContainers(containerList.dataset);
    setContainersNumber(containerList.totalCount);
    resetReload();
   }
  })();
 }, [reloadData]);

 useDebounce(
  async () => {
   if (!isLoading) {
    setCurrentPage(0);
    const containerList = await dispatch(
     getDockerProxy(id, currentPage, rowPerPage, searchRef?.current?.value)
    );
    setContainers(containerList.dataset);
    setContainersNumber(containerList.totalCount);
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  const containerList = await dispatch(
   getDockerProxy(id, 0, newRowsPerPage, searchRef?.current?.value)
  );
  setContainers(containerList.dataset);
  setContainersNumber(containerList.totalCount);
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  const containerList = await dispatch(
   getDockerProxy(id, startIndex, rowPerPage, searchRef?.current?.value)
  );
  setContainers(containerList.dataset);
  setContainersNumber(containerList.totalCount);
 };

 const handleCollapse = async (index: number) => {
  setOpen(open === index ? -1 : index);
 };

 return isLoading ? (
  <></>
 ) : container.length > 0 || !listWasEmpty ? (
  <Stack>
   <Stack direction="row" justifyContent="space-between" p={2}>
    <Stack direction="row" alignItems="center">
     <Typography fontWeight="bold" variant="caption">
      {containerNumber} <FormattedMessage id="docker.container.totalContainers" />
     </Typography>
     <TextField
      size="small"
      autoComplete="new-password"
      label={<FormattedMessage id="docker.container.searchContainer" />}
      inputRef={searchRef}
      sx={{ ml: 2 }}
      InputLabelProps={{ shrink: true }}
      InputProps={{ startAdornment: <SearchIcon color="disabled" />, autoComplete: "off" }}
      onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
     />
    </Stack>
    <Stack>
     <AddProxyContainer id={id} />
    </Stack>
   </Stack>
   <Stack>
    <TableContainer component="div">
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
       <TableRow>
        <TableCell>
         <FormattedMessage id="docker.container.name" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.container.status" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.container.image" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="docker.container.ports" />
        </TableCell>
        <TableCell align="right">
         <FormattedMessage id="app.actions" />
        </TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {container.map((row, index) => (
        <Fragment key={`isp-config-list-${index}`}>
         <TableRow
          onClick={() => row.type === "proxy-site" && handleCollapse(index)}
          sx={{ cursor: "row-resize" }}>
          <TableCell component="th" scope="row">
           <Typography variant="caption">{row.name}</Typography>
          </TableCell>
          <TableCell component="th" scope="row">
           <Chip size="small" label={row.status} color="success" />
          </TableCell>
          <TableCell component="th" scope="row">
           <Typography variant="caption">{row.image}</Typography>
          </TableCell>
          <TableCell component="th" scope="row">
           <Stack direction="row" spacing={1}>
            {separatePorts(row.ports).map((element, key) => {
             return (
              <Chip
               key={`docker-port-${key}`}
               label={formatPort(element)}
               size="small"
               color="info"
              />
             );
            })}
           </Stack>
          </TableCell>
          <TableCell
           align="right"
           onClick={(e) => {
            e.stopPropagation();
           }}>
           <Stack direction="row" justifyContent="flex-end">
            <DeleteProxyContainer
             id={id}
             name={row.name}
             proxy_id={row.container_id}
             type={row.type}
            />
           </Stack>
          </TableCell>
         </TableRow>
         {row.type === "proxy-site" && (
          <TableRow>
           <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open === index} timeout="auto" unmountOnExit>
             <DockerWebsite
              id={id}
              proxy_id={row.container_id}
              reloadData={reloadData}
              resetReload={resetReload}
             />
            </Collapse>
           </TableCell>
          </TableRow>
         )}
        </Fragment>
       ))}
      </TableBody>
     </Table>
    </TableContainer>
    <TablePagination
     component="div"
     count={containerNumber}
     page={currentPage}
     rowsPerPage={rowPerPage}
     onPageChange={handleOnPageChange}
     onRowsPerPageChange={handleOnRowsPerPageChange}
    />
   </Stack>
  </Stack>
 ) : (
  <Stack alignItems="center">
   <EmptyList />
   <AddProxyContainer id={id} />
  </Stack>
 );
};

export default DockerProxy;
