import React, { ReactElement, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useIntl } from "react-intl";

import CloseIcon from "@mui/icons-material/Close";

import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";

type Props = {
 text: string;
 tooltip?: string;
 children?: ReactElement;
};

const ClickToCopy = ({ text, tooltip = "app.copyToClipboard", children }: Props): ReactElement => {
 const intl = useIntl();

 const [open, setOpen] = useState(false);
 const [visible, setVisible] = useState(typeof children === "undefined");

 const handleClick = () => {
  navigator.clipboard.writeText(text);
  setOpen(true);
 };

 const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
  if (reason === "clickaway") {
   return;
  }

  setOpen(false);
 };

 const action = (
  <React.Fragment>
   <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
    <CloseIcon fontSize="small" />
   </IconButton>
  </React.Fragment>
 );

 return (
  <Stack
   direction="row"
   alignItems="center"
   onMouseEnter={() => setVisible(true)}
   onMouseLeave={() => setVisible(typeof children === "undefined")}>
   <Stack component="span">{children || null}</Stack>
   <CopyToClipboard text={text || ""}>
    <Tooltip title={intl.formatMessage({ id: tooltip })} placement="top">
     <IconButton
      onClick={handleClick}
      sx={{ p: 0, my: 0, visibility: visible ? "block" : "hidden" }}>
      <IconSelector icon="ClipboardIcon" props={{ fontSize: "small" }} />
     </IconButton>
    </Tooltip>
   </CopyToClipboard>
   <Snackbar
    open={open}
    autoHideDuration={2000}
    onClose={handleClose}
    message={intl.formatMessage({ id: "app.copyToClipboard" })}
    action={action}
   />
  </Stack>
 );
};

export default ClickToCopy;
