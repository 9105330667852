import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postCheckForProxy, postInstallProxy } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const AddProxyContainer = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [proxyType, setProxyType] = useState<string>("");
 const [proxyName, setProxyName] = useState<string>("");
 const [proxyAlreadyCreated, setProxyAlreadyCreated] = useState<boolean>(true);

 const handleOpen = async () => {
  reset();
  setProxyAlreadyCreated(await dispatch(postCheckForProxy(id)));
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postInstallProxy(id, proxyName, proxyType));
  handleClose();
  setLoading(false);
 };

 const checkDisabled = (): boolean => {
  return proxyName === "" || proxyType === "";
 };

 const reset = () => {
  setProxyName("");
  setProxyType("");
 };

 return (
  <>
   <Button onClick={handleOpen} variant="kxActionButton">
    <FormattedMessage id="docker.proxy.addContainer" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.proxy.addContainer" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || checkDisabled()}>
    <Stack spacing={2}>
     <Typography>
      <FormattedMessage id="docker.proxy.addContainerMessage" />
     </Typography>
     <Stack direction="row" spacing={2}>
      <FormControl fullWidth>
       <InputLabel>
        <FormattedMessage id="docker.proxy.type" />
       </InputLabel>
       <Select
        value={proxyType || ""}
        label={<FormattedMessage id="docker.proxy.type" />}
        onChange={(e) => setProxyType(e.target.value)}>
        <MenuItem key="protocol-mail" value={"proxy"}>
         Standard proxy
        </MenuItem>
        <MenuItem disabled={proxyAlreadyCreated} key="protocol-dom" value={"proxy-site"}>
         Site container proxy
        </MenuItem>
       </Select>
      </FormControl>
     </Stack>
     {proxyType !== "proxy-site" && (
      <TextField
       autoComplete="off"
       label={intl.formatMessage({ id: "docker.proxy.containerName" })}
       InputLabelProps={{ shrink: true }}
       onChange={({ currentTarget }) => setProxyName(currentTarget.value)}
      />
     )}
    </Stack>
   </AppModal>
  </>
 );
};

export default AddProxyContainer;
