import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import DockerOrdersReducer from "redux/reducers/dockerOrderReducer";
import notificationReducer from "redux/reducers/notificationReducer";

import {
 IContainerAPI,
 IContainerData,
 IDockerAPI,
 IDockerNetworkAPI,
 IDockerOrdersAPI,
 IDockerPhp,
 IDockerPorts,
 IDockerRepo,
 IDockerServiceAPI,
 IDockerSite,
 IDockerSiteAPI,
 IDockerStackAPI,
 IDockerVolumeAPI
} from "types/api/dockerApiInterface";

import { ApiService } from "service/ApiService";

export const getDockerOrders =
 (page: number, limit: number, q?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDockerOrdersAPI>(
    `/docker/order/getorders?page=${page}&limit=${limit}&q=${q || ""}`
   );
   dispatch(DockerOrdersReducer.actions.setDockerOrders(data || {}));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load docker orders - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getDockerImages =
 (id: number, page: number, limit: number, q?: string): AppAction<Promise<IDockerAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDockerAPI>(
    `/docker/image/getimages?id_server=${id}&page=${page}&limit=${limit}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load images - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const getDockerContainers =
 (id: number, page: number, limit: number, q?: string): AppAction<Promise<IContainerAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IContainerAPI>(
    `/docker/container/getcontainers?id_server=${id}&page=${page}&limit=${limit}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load containers - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const getDockerProxy =
 (id: number, page: number, limit: number, q?: string): AppAction<Promise<IContainerAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IContainerAPI>(
    `/docker/container/getcontainers?id_server=${id}&page=${page}&limit=${limit}&q=${
     q || ""
    }&type=proxy`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load containers - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const getDockerNetworks =
 (id: number, page: number, limit: number, q?: string): AppAction<Promise<IDockerNetworkAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDockerNetworkAPI>(
    `/docker/network/getnetworks?id_server=${id}&page=${page}&limit=${limit}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load networks - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const getDockerVolumes =
 (id: number, page: number, limit: number, q?: string): AppAction<Promise<IDockerVolumeAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDockerVolumeAPI>(
    `/docker/volume/getvolumes?id_server=${id}&page=${page}&limit=${limit}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load volumes - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const getDockerStacks =
 (id: number, page: number, limit: number, q?: string): AppAction<Promise<IDockerStackAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDockerStackAPI>(
    `/docker/stack/getstacks?id_server=${id}&page=${page}&limit=${limit}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load stacks - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const getDockerServices =
 (id: number, page: number, limit: number, q?: string): AppAction<Promise<IDockerServiceAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDockerServiceAPI>(
    `/docker/service/getservices?id_server=${id}&page=${page}&limit=${limit}&q=${q || ""}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load services - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const getProxySites =
 (proxy_id: string, page: number, limit: number, q?: string): AppAction<Promise<IDockerSiteAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IDockerSiteAPI>(
    `/docker/site/getsites?page=${page}&limit=${limit}&q=${q || ""}&proxy_id=${proxy_id}`
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load sites - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return { dataset: [], totalCount: 0 };
  }
 };

export const getPhpVersions = (): AppAction<Promise<Array<IDockerPhp>>> => async (dispatch) => {
 try {
  const { data } = await ApiService<Array<IDockerPhp>>("/docker/php/getversions");
  return data;
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to get php versions - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
  return [];
 }
};

export const getRepositorys = (): AppAction<Promise<Array<IDockerRepo>>> => async (dispatch) => {
 try {
  const { data } = await ApiService<Array<IDockerRepo>>("/docker/repo/getrepo");
  return data;
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to get repository - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
  return [];
 }
};

export const getPorts = (): AppAction<Promise<Array<IDockerPorts>>> => async (dispatch) => {
 try {
  const { data } = await ApiService<Array<IDockerPorts>>("/docker/port/getports");
  return data;
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to get ports - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
  return [];
 }
};

export const getAllSites =
 (id: number): AppAction<Promise<Array<IDockerSite>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IDockerSite>>("/docker/site/getbyserverid", "POST", {
    server_id_db: id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get sites - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const getAllContainers =
 (id: number): AppAction<Promise<Array<IContainerData>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IContainerData>>(
    "/docker/container/getbyserverid",
    "POST",
    {
     server_id_db: id
    }
   );
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get containers - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postCreateContainer =
 (id: number, name: string, repo: string, php_id: string, site: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/container/installcontainer", "POST", {
    server_id_db: id,
    container_name: name,
    repo_name: repo,
    php_id: php_id,
    site_name: site
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Installing container",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install container - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteContainer =
 (id: number, name: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/container/disinstallcontainer", "POST", {
    server_id_db: id,
    container_name: name
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting container",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete container - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCheckForProxy =
 (id: number): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/container/checkproxy", "POST", {
    server_id_db: id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install proxy - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const postInstallProxy =
 (id: number, name: string, type: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/container/installproxy", "POST", {
    server_id_db: id,
    container_name: name,
    type: type
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Installing proxy",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to install proxy - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDisableProxy =
 (id: number, name: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/container/disableproxy", "POST", {
    server_id_db: id,
    container_name: name
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Disable proxy",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to disable proxy - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRedirectProxy =
 (id: number, name: string, port: number, domain: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>(
    "/docker/container/redirectproxytocontainer",
    "POST",
    {
     server_id_db: id,
     container_name: name,
     port: port,
     domain_name: domain
    }
   );
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Redirecting proxy",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to redirect proxy - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postCreateSite =
 (id: number, name: string, proxy_id: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/createsite", "POST", {
    server_id_db: id,
    site_name: name,
    proxy_id: proxy_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Creating site",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create site - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postApplySsl =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/applyssl", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Applying ssl",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to apply ssl - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteSite =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<boolean>("/docker/site/deletesite", "POST", {
    id: id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Deleting site",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete site - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
