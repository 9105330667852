import React, { ReactElement } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";

import CustomersActions from "components/profile/customers/CustomersActions";
import HostActions from "components/profile/hosts/HostActions";
import TeamsActions from "components/profile/teams/TeamsActions";

import { CloudboxData } from "types/global/cloudbox";
import { ruleData } from "types/global/firewall";
import { customerData, hostData } from "types/global/user";

import IconSelector from "../images/IconSelector";

type subnetType = {
 type: string;
 ip_range: string;
 network_zone: string;
 vswitch_id: number | null;
 gateway: string;
};

type ActionsType = {
 section: string;
 id: number;
 name?: string;
 protection?: boolean;
 rules?: Array<ruleData>;
 applied?: Array<number>;
 hzId?: string;
 cloudboxData?: CloudboxData;
 serversIds?: Array<number>;
 routes?: Array<{ destination: string; gateway: string }>;
 subnets?: Array<subnetType>;
 customer?: customerData;
 teamsUser?: string;
 host?: hostData;
};

const ActionsMenu = ({
 section,
 id,
 name,
 protection,
 hzId,
 cloudboxData,
 rules,
 applied,
 serversIds,
 routes,
 subnets,
 customer,
 teamsUser,
 host
}: ActionsType): ReactElement => {
 const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 const RenderMenu = (): ReactElement => {
  switch (section) {
   case "customers":
    return <CustomersActions id={id} customer={customer} />;
   case "teams":
    return <TeamsActions id={id} user={teamsUser} />;
   case "host":
    return <HostActions host={host} />;
   default:
    return <></>;
  }
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <RenderMenu />
    </Stack>
   </Menu>
  </>
 );
};

export default ActionsMenu;
