import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";
import teamsReducer from "redux/reducers/teamsReducer";

import { ITeamsApi } from "types/api/teamsApiInterface";

import { ApiService } from "service/ApiService";

export const getAllSecondLevelUsers =
 (
  currentIndex: number,
  sizePerPage: number,
  parentid: number,
  q?: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<ITeamsApi>(
    `/teams/allteams?currentIndex=${currentIndex}?sizePerPage=${sizePerPage}?q=${q}`
   );
   dispatch(teamsReducer.actions.setTeams(data || {}));
  } catch (error) {
   console.warn("FAILED TO GET TEAMS");
  }
 };

export const postAddTeamsUser =
 (userid: number, email: string, password: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/teams/addteams", "POST", {
    email,
    password
   });
   if (data) {
    dispatch(getAllSecondLevelUsers(0, 10, userid));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "User created successfully",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const putUpdateTeamsUser =
 (id: number, email: string, userid: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService(`/teams/updateteams/${id}`, "PUT", {
    email
   });
   if (data) {
    dispatch(getAllSecondLevelUsers(0, 10, userid));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "User updated successfully",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const putUpdateTeamsPassword =
 (id: number, oldPassword: string, newPassword: string, userid: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService(`/teams/updatepassword/${id}`, "PUT", {
    oldPassword,
    newPassword
   });
   if (data) {
    dispatch(getAllSecondLevelUsers(0, 10, userid));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "User updated successfully",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteTeamsUser =
 (id: number, userid: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/teams/deleteteams", "POST", {
    id
   });
   if (data) {
    dispatch(getAllSecondLevelUsers(0, 10, userid));
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "User deleted successfully",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete user - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
