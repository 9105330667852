import React, { ReactElement } from "react";

import Tooltip from "@mui/material/Tooltip";

import { EServerProvider } from "types/redux/serverInterface";

type Props = {
 provider: string | null;
 width?: number;
 height?: number;
};

const ProviderImage = ({ provider, width = 25, height = 20 }: Props): ReactElement => {
 const image = () => {
  switch (provider) {
   case EServerProvider.HETZNER:
    return (
     //   TO DO
     //  <img
     //   width={width}
     //   height={height}
     //   src={`${process.env.PUBLIC_URL}/svg/providers/hetzner-.svg`}
     //   alt="hetzner"
     //  />
     <img
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/providers/konsolex.svg`}
      alt="konsolex"
     />
    );
   default:
    return (
     <img
      width={width}
      height={height}
      src={`${process.env.PUBLIC_URL}/svg/providers/konsolex.svg`}
      alt="konsolex"
     />
    );
  }
 };

 return (
  // TO DO
  // <Tooltip title={provider || ""} placement="top">
  //  {image()}
  // </Tooltip>
  <Tooltip title={"konsolex" || ""} placement="top">
   {image()}
  </Tooltip>
 );
};

export default ProviderImage;
