import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import AdminWrapper from "components/shared/admin/AdminWrapper";
import ClickToCopy from "components/shared/clipboardCopy/ClickToCopy";
import IconSelector from "components/shared/images/IconSelector";

import { getServerDetails } from "redux/selectors/serversSelector";

import ServerChangeName from "./credentialsActions/ServerChangeName";
import ServerResetPassword from "./credentialsActions/ServerResetPassword";
//import ServerUpdatePassword from "./credentialsActions/ServerUpdatePassword";

const ServerCredential = (): ReactElement => {
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const server = useSelector(getServerDetails);
 // const [modalType, setModalType] = useState<"serverName"  | "password">("serverName");
 const [showPassword, setShowPassword] = useState<string>("password");

 return (
  <>
   <Paper elevation={0} sx={{ mt: 3, borderRadius: "10px", boxShadow: 0 }}>
    <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} pt={1}>
     <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
     <Typography variant="h6" component="h2" ml={1}>
      <FormattedMessage id="server.credential.title" />{" "}
     </Typography>
    </Stack>

    <Stack
     direction={"row"}
     alignItems="center"
     mt={2}
     p={1}
     justifyContent="space-between"
     width="100%">
     <Stack>
      <Typography noWrap variant="subtitle1" px={1} fontWeight="bold">
       <FormattedMessage id="server.credential.name" />
      </Typography>
     </Stack>

     <Stack direction="row" alignItems="center" pr={2}>
      <ClickToCopy text={server.serverName || ""}>
       <Typography variant="subtitle2">{server.serverName || "N/A"}</Typography>
      </ClickToCopy>
      <ServerChangeName id={server.id || 0} name={server.serverName || "N/A"} />
     </Stack>
    </Stack>

    <Divider />

    <AdminWrapper showIcon={false} forceRender={server?.type?.includes("custom")}>
     <Stack
      direction={desktopViewPort ? "row" : "column"}
      justifyContent="space-between"
      alignItems="center"
      p={1}
      width="100%">
      <Stack>
       <Typography variant="subtitle1" px={1} fontWeight="bold">
        <FormattedMessage id="server.credential.password" />
       </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end">
       <AdminWrapper
        showIcon={server?.type?.includes("custom") ? false : true}
        forceRender={server?.type?.includes("custom")}>
        <ServerResetPassword id={server?.id || 0} />
       </AdminWrapper>
       {/*<AdminWrapper>
        <ServerUpdatePassword id={server?.id || 0} password={server.password || ""} />
 </AdminWrapper>*/}
       <TextField
        value={server.password || ""}
        type={showPassword}
        size="small"
        sx={{ width: 200 }}
        InputProps={{
         endAdornment: (
          <InputAdornment position="end">
           <IconButton
            aria-label="toggle password visibility"
            onClick={() => {
             setShowPassword(showPassword === "text" ? "password" : "text");
            }}
            edge="end">
            {showPassword ? <VisibilityOff /> : <Visibility />}
           </IconButton>
          </InputAdornment>
         ),
         readOnly: true
        }}
       />
      </Stack>
     </Stack>
    </AdminWrapper>
   </Paper>
  </>
 );
};

export default ServerCredential;
