import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";

import { phpMaxExec, phpMaxVars, phpMemoryLimit, phpPostMax, phpTimezone } from "constants/phpData";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import {
 postConfigPhp,
 postGetServerPhpData,
 postGetServerPhpVersion
} from "redux/handlers/serverHandler";

import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

const defaultPhpValues = {
 phpVersion: "",
 phpUploadLimit: 8,
 phpMaxVars: 1000,
 phpMaxExec: 30,
 phpMemoryLimit: 128,
 phpTimezone: "",
 phpError: false
};

type PhpData = {
 phpVersion: string;
 phpUploadLimit: number;
 phpMaxVars: number;
 phpMaxExec: number;
 phpTimezone: string;
 phpMemoryLimit: number;
 phpError: boolean;
};

const ConfigPhp = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));
 const { socket } = useContext(AppContext);

 const server = useSelector(getServerDetails);

 const [status, setStatus] = useState<string>("no");
 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [versions, setVersions] = useState<Array<string>>([]);

 useEffect(() => {
  (async () => {
   if (status !== "no") setVersions(await dispatch(postGetServerPhpVersion(server.serverName)));
  })();
 }, [status]);

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("server_logs", (value): void => {
    if (value?.length > 0) {
     for (let i = 0; i < value.length; i++) {
      if (server.agentoken === value[i]?.token) {
       setStatus(value[i]?.php_ver || "no");
      }
     }
    }
   });
  }
  return () => {
   !isNil(socket) && socket.off("server_logs");
  };
 }, [socket, server]);

 const handleGetPhpData = async (version: string) => {
  const receivedData = await dispatch(postGetServerPhpData(version, server.agentoken || ""));
  setValue("phpError", receivedData?.phpErrors || false);
  setValue("phpTimezone", receivedData?.timezone || "");
  setValue("phpMaxExec", receivedData?.maxExecution || 30);
  setValue("phpMaxVars", receivedData?.maxVars || 1000);
  setValue("phpUploadLimit", receivedData?.maxUpload || 8);
  setValue("phpMemoryLimit", receivedData?.memoryLimit || 128);
 };

 const handleOpen = async () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, formState, setValue, getValues, watch, register } = useForm({
  defaultValues: defaultPhpValues
 });

 const onSubmit: SubmitHandler<PhpData> = async (data: PhpData) => {
  setIsLoading(true);
  await dispatch(
   postConfigPhp(
    server.agentoken || "",
    data.phpVersion.split(" ").pop() || "",
    data.phpUploadLimit,
    data.phpMemoryLimit,
    data.phpMaxVars,
    data.phpMaxExec,
    data.phpError,
    data.phpTimezone
   )
  );
  setIsLoading(false);
  setOpenModal(false);
 };

 const checkForData = (): boolean => {
  return (
   watch("phpVersion") === "" ||
   !watch("phpMaxExec") ||
   !watch("phpMaxVars") ||
   !watch("phpMemoryLimit") ||
   watch("phpTimezone") === "" ||
   !watch("phpUploadLimit")
  );
 };

 return (
  <>
   {status === "no" ? (
    <IconButton disabled={status === "no" || true}>
     <IconSelector icon="RebuildIcon" props={{ fontSize: "small" }} />
    </IconButton>
   ) : (
    <Tooltip title={intl.formatMessage({ id: "server.services.phpConfig" })} placement="top">
     <IconButton onClick={handleOpen}>
      <IconSelector icon="RebuildIcon" props={{ fontSize: "small" }} />
     </IconButton>
    </Tooltip>
   )}
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    disabled={checkForData()}
    handleConfirm={handleSubmit(onSubmit)}
    title={`${intl.formatMessage({ id: "server.service.config" })} PHP`}>
    <Stack>
     <Stack>
      <Controller
       name="phpVersion"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         value={getValues("phpVersion").toString()}
         sx={{ my: 2 }}
         options={versions}
         onChange={(e, value) => {
          !isNil(value) && setValue("phpVersion", value);
          !isNil(value) && handleGetPhpData(value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           {...register("phpVersion")}
           label={intl.formatMessage({ id: "server.service.phpVersion" })}
           error={formState.isDirty && !!formState?.errors?.phpVersion}
           InputLabelProps={{ shrink: true }}
          />
         )}
        />
       )}
      />
      <Alert severity="info">
       <FormattedMessage id="server.services.phpConfigInfo" />
      </Alert>
     </Stack>
     <Stack direction={desktopViewPort ? "row" : "column"} spacing={2} my={2}>
      <Controller
       name="phpMaxVars"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         disabled={!getValues("phpVersion")?.length}
         value={getValues("phpMaxVars").toString()}
         sx={{ my: 2 }}
         options={phpMaxVars}
         onChange={(e, value) => {
          !isNil(value) && setValue("phpMaxVars", Number(value));
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           {...register("phpMaxVars")}
           label={intl.formatMessage({ id: "server.service.phpMaxVars" })}
           error={formState.isDirty && !!formState?.errors?.phpMaxVars}
           InputLabelProps={{ shrink: true }}
          />
         )}
        />
       )}
      />
      <Controller
       name="phpMaxExec"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         value={getValues("phpMaxExec").toString()}
         disabled={!getValues("phpVersion")?.length}
         sx={{ my: 2 }}
         options={phpMaxExec}
         onChange={(e, value) => {
          !isNil(value) && setValue("phpMaxExec", Number(value));
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           {...register("phpMaxExec")}
           label={intl.formatMessage({ id: "server.service.phpMaxExec" })}
           error={formState.isDirty && !!formState?.errors?.phpMaxExec}
           InputLabelProps={{ shrink: true }}
          />
         )}
        />
       )}
      />
     </Stack>
     <Stack direction={desktopViewPort ? "row" : "column"} spacing={2}>
      <Controller
       name="phpMemoryLimit"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         value={getValues("phpMemoryLimit").toString()}
         disabled={!getValues("phpVersion")?.length}
         sx={{ my: 2 }}
         options={phpMemoryLimit}
         onChange={(e, value) => {
          !isNil(value) && setValue("phpMemoryLimit", Number(value));
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           {...register("phpMemoryLimit")}
           label={intl.formatMessage({ id: "server.service.phpMemoryLimit" })}
           error={formState.isDirty && !!formState?.errors?.phpMemoryLimit}
           InputLabelProps={{ shrink: true }}
          />
         )}
        />
       )}
      />
      <Controller
       name="phpTimezone"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         value={getValues("phpTimezone")}
         disabled={!getValues("phpVersion")?.length}
         sx={{ my: 2 }}
         options={phpTimezone}
         onChange={(e, value) => {
          !isNil(value) && setValue("phpTimezone", value);
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           {...register("phpTimezone")}
           label={intl.formatMessage({ id: "server.service.phpTimezone" })}
           error={watch("phpTimezone") === ""}
           InputLabelProps={{ shrink: true }}
          />
         )}
        />
       )}
      />
     </Stack>
     <Stack direction={desktopViewPort ? "row" : "column"} spacing={2} my={2}>
      <Controller
       name="phpUploadLimit"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         disabled={!getValues("phpVersion")?.length}
         value={getValues("phpUploadLimit").toString()}
         sx={{ my: 2 }}
         options={phpPostMax}
         onChange={(e, value) => {
          !isNil(value) && setValue("phpUploadLimit", Number(value));
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           {...register("phpUploadLimit")}
           label={intl.formatMessage({ id: "server.service.phpPostMax" })}
           error={formState.isDirty && !!formState?.errors?.phpUploadLimit}
           InputLabelProps={{ shrink: true }}
          />
         )}
        />
       )}
      />
      <Controller
       name="phpError"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         control={
          <Switch
           {...field}
           {...register("phpError")}
           checked={getValues("phpError")}
           onChange={(e, checked) => {
            setValue("phpError", checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "server.service.phpError" })}
        />
       )}
      />
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default ConfigPhp;
