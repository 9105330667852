import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

// import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";

import { logoutAction } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

const Test = (): ReactElement => {
 const dispatch = useAppDispatch();

 const user = useSelector((state: any) => state.user);

 const handleLogout = (): void => {
  dispatch(logoutAction());
 };

 return (
  <div>
   <h2>Ciao {`${user.firstname} ${user.lastname}`}</h2>
   <Button variant="kxActionButton" color="primary" onClick={handleLogout}>
    Logout
   </Button>
   {/*<NavLink to={"/test"}>Go Test</NavLink>*/}
  </div>
 );
};

export default Test;
