type linkType = {
 name: string;
 url: string;
 icon?: string;
}[];

export const headerLinks: linkType = [
 {
  name: "Domini",
  url: "/domains",
  icon: "DomainIcon"
 },
 {
  name: "Server",
  url: "/servers",
  icon: "ServerIcon"
 },
 {
  name: "App",
  url: "/app/sites",
  icon: "AppIcon"
 },
 {
  name: "Addons",
  url: "/addons/cloudbox",
  icon: "AddonsIcon"
 }
];

export const profileMenuLinks: linkType = [
 {
  name: "profile.userprofile",
  url: "user",
  icon: "ProfileIcon"
 },
 {
  name: "profile.settings",
  url: "settings",
  icon: "SettingsApplicationsIcon"
 },
 {
  name: "profile.clients",
  url: "customers",
  icon: "ClientIcon"
 },
 {
  name: "profile.teams",
  url: "settings",
  icon: "TeamsIcon"
 },
 {
  name: "profile.recharge",
  url: "recharge",
  icon: "RechargeIcon"
 },
 {
  name: "profile.host",
  url: "settings",
  icon: "HostIcon"
 },
 {
  name: "profile.services",
  url: "services",
  icon: "ServiceIcon"
 },
 {
  name: "profile.credits",
  url: "credits",
  icon: "CreditsIcon"
 },

 {
  name: "profile.orders",
  url: "orders",
  icon: "OrdersIcon"
 }
];

export const serverLinks: linkType = [
 {
  name: "server.details",
  url: "specification",
  icon: "DetailsIcon"
 },
 {
  name: "server.services",
  url: "services",
  icon: "ServiceIcon"
 },
 {
  name: "server.backups",
  url: "backups",
  icon: "BackupIcon"
 },
 {
  name: "server.upgrade",
  url: "upgrade",
  icon: "UpgradeIcon"
 },
 {
  name: "server.rebuild",
  url: "rebuild",
  icon: "RebuildIcon"
 }
];

export const domainsLinks: linkType = [
 {
  name: "domains.details",
  url: "details",
  icon: "DetailsIcon"
 },
 {
  name: "domains.dns",
  url: "dns",
  icon: "ServiceIcon"
 },
 {
  name: "domains.owners",
  url: "owners",
  icon: "ProfileIcon"
 },
 {
  name: "domains.changeNs",
  url: "nameservers",
  icon: "RebuildIcon"
 }
];

export const balancerLinks: linkType = [
 {
  name: "addons.loadBalancer.details",
  url: "details",
  icon: "DetailsIcon"
 },
 {
  name: "addons.loadBalancer.certificates",
  url: "certificate",
  icon: "CardMembershipIcon"
 },
 {
  name: "addons.loadBalancer.services",
  url: "services",
  icon: "ServiceIcon"
 },
 {
  name: "addons.loadBalancer.targets",
  url: "targets",
  icon: "TrackChangesIcon"
 },
 {
  name: "addons.loadBalancer.networking",
  url: "networking",
  icon: "NetworkIcon"
 },
 {
  name: "addons.loadBalancer.rescale",
  url: "rescale",
  icon: "UpgradeIcon"
 }
];

export const cloudboxLinks: linkType = [
 {
  name: "addons.cloudbox.details",
  url: "details",
  icon: "DetailsIcon"
 },
 {
  name: "addons.cloudbox.snapshots",
  url: "snapshots",
  icon: "SnapshotIcon"
 },
 {
  name: "addons.cloudbox.upgrade",
  url: "upgrade",
  icon: "UpgradeIcon"
 }
];

export const networkLinks: linkType = [
 {
  name: "addons.network.details",
  url: "details",
  icon: "DetailsIcon"
 },
 {
  name: "addons.network.subnet",
  url: "subnets",
  icon: "SubnetIcon"
 },
 {
  name: "addons.network.routes",
  url: "routes",
  icon: "RouteIcon"
 }
];

export const firewallLinks: linkType = [
 {
  name: "addons.firewall.details",
  url: "details",
  icon: "DetailsIcon"
 },
 {
  name: "addons.firewall.inboundRules",
  url: "inboundrules",
  icon: "InboundIcon"
 },
 {
  name: "addons.firewall.outboundRules",
  url: "outboundrules",
  icon: "OutboundIcon"
 }
];

export const serverAddonsLinks: linkType = [
 {
  name: "server.addons.snapshot",
  url: "snapshot",
  icon: "SnapshotIcon"
 },
 {
  name: "server.addons.volumes",
  url: "volumes",
  icon: "VolumeIcon"
 },
 {
  name: "server.addons.floatingIp",
  url: "floatingip",
  icon: "SettingsInputAntennaIcon"
 },
 {
  name: "server.addons.network",
  url: "network",
  icon: "NetworkIcon"
 }
];

export const sitesLinks: linkType = [
 {
  name: "sites.details",
  url: "details",
  icon: "DetailsIcon"
 },
 {
  name: "sites.backup",
  url: "backup",
  icon: "BackupIcon"
 },
 {
  name: "sites.cronjob",
  url: "cronjob",
  icon: "CronjobIcon"
 }
];

export const mailsLinks: linkType = [
 {
  name: "mails.details",
  url: "details",
  icon: "DetailsIcon"
 },
 {
  name: "mails.backup",
  url: "backup",
  icon: "BackupIcon"
 }
];

export const addonsLinks: linkType = [
 {
  name: "addons.cloudbox",
  url: "cloudbox",
  icon: "CloudboxIcon"
 },
 {
  name: "addons.floatingIp",
  url: "floatingip",
  icon: "SettingsInputAntennaIcon"
 },
 {
  name: "addons.network",
  url: "network",
  icon: "NetworkIcon"
 },
 {
  name: "addons.snapshots",
  url: "snapshots",
  icon: "SnapshotIcon"
 },
 {
  name: "addons.volumes",
  url: "volumes",
  icon: "VolumeIcon"
 },
 {
  name: "addons.firewall",
  url: "firewall",
  icon: "FirewallIcon"
 },
 {
  name: "addons.loadBalancer",
  url: "loadBalancer",
  icon: "BalanceIcon"
 }
];
export const appLinks: linkType = [
 {
  name: "app.sitesTitle",
  url: "sites",
  icon: "WebIcon"
 },
 {
  name: "app.mail",
  url: "mail",
  icon: "MailIcon"
 },
 {
  name: "app.zone",
  url: "zone",
  icon: "ZoneIcon"
 }
];
