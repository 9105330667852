import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

//import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
//import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
//import Box from "@mui/material/Box";
//import Collapse from "@mui/material/Collapse";
//import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
//import Table from "@mui/material/Table";
//import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
//import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { getServerDetails } from "redux/selectors/serversSelector";

import ConfigPhp from "./servicesActions/config/ConfigPhp";
import ServiceLogs from "./servicesActions/logs/ServiceLogs";
import ServicePermit from "./servicesActions/permit/ServicePermit";
import RebootService from "./servicesActions/reboot/RebootService";
import ServiceRectify from "./servicesActions/repair/ServiceRectify";
import ServiceRepair from "./servicesActions/repair/ServiceRepair";
import ServiceConfig from "./servicesActions/ServiceConfig";
import ServiceCpu from "./servicesActions/ServiceCpu";
import ServiceStatus from "./servicesActions/ServiceStatus";

const ServiceTableData = ({
 service,
 actions,
 name,
 sqlLogs
}: {
 service: string;
 actions: Array<string>;
 name: string;
 sqlLogs: string;
}): ReactElement => {
 const server = useSelector(getServerDetails);

 //const [open, setOpen] = useState<boolean>(false);

 /*const RenderPostfixData = (): ReactElement => {
  return (
   <TableRow>
    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
     <Collapse in={open} timeout="auto" unmountOnExit>
      <Box sx={{ margin: 1 }}>
       <Table size="small" aria-label="purchases">
        <TableBody>
         <TableRow>
          <TableCell>
           <Typography>PolicyD</Typography>
          </TableCell>
          <TableCell>Here be actions</TableCell>
         </TableRow>
         <TableRow>
          <TableCell>
           <Typography>Webmail</Typography>
          </TableCell>
          <TableCell>Here be actions</TableCell>
         </TableRow>
        </TableBody>
       </Table>
      </Box>
     </Collapse>
    </TableCell>
   </TableRow>
  );
 };*/

 const RenderAction = ({ action }: { action: string }): ReactElement => {
  switch (action) {
   case "permit":
    return <ServicePermit />;
   case "config":
    return <ServiceConfig service={service} />;
   case "phpConfig":
    return <ConfigPhp />;
   case "logs":
    return <ServiceLogs service={service} />;
   case "repair":
    return <ServiceRepair name={service} sqlLogs={sqlLogs} />;
   case "rectify":
    return <ServiceRectify service={service} />;
   default:
    return <RebootService name={service} service={service} id={server.id || 0} />;
  }
 };

 return (
  <>
   <TableRow>
    <TableCell>
     <Stack direction="row" spacing={2} alignItems="center">
      <Typography>{name}</Typography>
      {/*service === "postfix" && (
       <Tooltip title={intl.formatMessage({ id: "server.services.servicePlugin" })} placement="top">
        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
         {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
       </Tooltip>
      )*/}
     </Stack>
    </TableCell>
    <TableCell>
     <ServiceStatus service={service} />
    </TableCell>
    <TableCell>
     <ServiceCpu service={service} />
    </TableCell>
    <TableCell>
     {actions.map((element, index) => {
      return <RenderAction key={`service-action-${index}`} action={element} />;
     })}
    </TableCell>
   </TableRow>
   {/*<RenderPostfixData />*/}
  </>
 );
};

export default ServiceTableData;
