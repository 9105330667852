import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import MailsReducer from "redux/reducers/mailsReducer";
import notificationReducer from "redux/reducers/notificationReducer";

import { IAutoresponder, ISpamSelector } from "types/api/mailApiInterface";

import { ApiService } from "service/ApiService";

export const getAllMailsDomain =
 (
  currentIndex: number,
  sizePerPage: number,
  isp_id: number,
  q?: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService(
    `/maildomain/getmaildomain?page=${currentIndex}&limit=${sizePerPage}&q=${
     q || ""
    }&isp_id=${isp_id}`
   );
   dispatch(MailsReducer.actions.setMails(data || []));
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load domains - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getSpamfilterData = async (): Promise<Array<ISpamSelector>> => {
 try {
  const { data } = await ApiService("/maildomain/getmailpolicy");
  return data;
 } catch (error: any) {
  console.warn(error);
  return [];
 }
};

export const postAddMailDomain =
 (domain: string, isp_id: number, dkim: boolean, policy_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/maildomain/addmaildomain", "POST", {
    domain: domain,
    isp_id: isp_id,
    dkim: dkim,
    policy_id: policy_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Mail domain created",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create mail domain - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteMailDomain =
 (id: number, isp_id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/maildomain/deletemaildomain", "POST", {
    id: id,
    isp_id: isp_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Mail domain deleted",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete mail domain - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUpdateMailDomain =
 (
  id: number,
  isp_id: number,
  dkim: boolean,
  policy_id: number,
  domain: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/maildomain/updatemaildomain", "POST", {
    id: id,
    isp_id: isp_id,
    dkim: dkim,
    policy_id: policy_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Mail domain updated",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update mail domain - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postStatusMailDomain =
 (id: number, isp_id: number, domain?: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/maildomain/setstatus", "POST", {
    id: id,
    isp_id: isp_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Mail domain status updated",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update mail domain status - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postAddMailbox =
 (
  maildomain_id: number,
  isp_id: number,
  name: string,
  password: string,
  domain: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/mailbox/addmailbox", "POST", {
    maildomain_id: maildomain_id,
    isp_id: isp_id,
    name: name,
    password: password
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Mailbox created",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create mailbox - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDeleteMailbox =
 (id: number, isp_id: number, domain: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/mailbox/deletemailbox", "POST", {
    id: id,
    isp_id: isp_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Mailbox deleted",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to delete mailbox - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postEditMailbox =
 (
  id: number,
  isp_id: number,
  name: string,
  password: string,
  quota: number,
  disabledeliver: boolean,
  disabledeimap: boolean,
  disablesmtp: boolean,
  cc: string,
  domain: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/mailbox/updatemailbox", "POST", {
    id: id,
    isp_id: isp_id,
    name: name,
    password: password,
    quota: quota,
    disabledeliver: disabledeliver,
    disabledeimap: disabledeimap,
    disablesmtp: disablesmtp,
    cc: cc
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Mailbox updated",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update mailbox - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postEditMailboxToggles =
 (
  id: number,
  isp_id: number,
  disabledeliver: boolean,
  disabledeimap: boolean,
  disablesmtp: boolean,
  domain: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/mailbox/updatemailbox", "POST", {
    id: id,
    isp_id: isp_id,
    disabledeliver: disabledeliver,
    disableimap: disabledeimap,
    disablesmtp: disablesmtp
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Mailbox updated",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update mailbox - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getMailAutoresponder =
 (id: number): AppAction<Promise<IAutoresponder | null>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IAutoresponder>("/mailbox/getautoresponder", "POST", {
    id: id
   });
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get autoresponder data - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const postEditMailAutoresponder =
 (
  id: number,
  autoresponder: boolean,
  autoresponder_subject: string,
  autoresponder_text: string,
  autoresponder_start_date: string,
  autoresponder_end_date: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/mailbox/updateautoresponder", "POST", {
    id: id,
    autoresponder: autoresponder,
    autoresponder_subject: autoresponder_subject,
    autoresponder_text: autoresponder_text,
    autoresponder_start_date: autoresponder_start_date,
    autoresponder_end_date: autoresponder_end_date
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Mail autoresponder updated",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to update autoresponder - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
