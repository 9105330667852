import React, { ReactElement } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { getServerNameFromId } from "helpers/addons";
import { formatExpireColor } from "helpers/numberFormatting";

import DvrIcon from "@mui/icons-material/Dvr";

import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import ChangeProtection from "components/addons/actions/ChangeProtection";
import SnapshotsActions from "components/addons/snapshots/SnapshotsActions";
import AdminLogAsUser from "components/shared/admin/AdminLogAsUser";

import { IAllServers } from "types/api/serversApiInterface";
import { snapshotTableData } from "types/global/table";

const SnapshotsTable = ({
 rowData,
 index,
 servers
}: {
 rowData: snapshotTableData;
 index: number;
 servers: Array<IAllServers>;
}): ReactElement => {
 const intl = useIntl();

 return (
  <TableRow key={`snapshot-list-${index}`}>
   <TableCell component="th" scope="row">
    <Stack direction="column">
     <Typography variant="inherit" fontWeight="bold">
      {rowData.description}
     </Typography>
     <Stack direction="row" alignItems="center">
      <DvrIcon fontSize="small" sx={{ marginRight: 1 }} />
      <Typography variant="inherit">
       {rowData.server_id ? (
        getServerNameFromId(rowData.server_id, servers)
       ) : (
        <FormattedMessage id="server.snapshot.snapshotNotAvailable" />
       )}
      </Typography>
     </Stack>
     <Stack onClick={(event) => event.stopPropagation()} sx={{ cursor: "pointer" }}>
      <AdminLogAsUser userid={rowData.userid || ""} owner={rowData.personal_name || ""} />
     </Stack>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Typography variant="inherit">{`${Number(rowData.image_size).toFixed(2)} ${intl.formatMessage({
     id: "server.manage.gb"
    })}`}</Typography>
   </TableCell>
   <TableCell>
    <Stack>
     <Typography
      fontSize={"small"}
      variant="caption"
      color={formatExpireColor(rowData.expire) ? "#000" : "#f44336"}>
      {`${intl.formatMessage({
       id: "server.snapshot.snapshotExpiring"
      })}: `}
      <FormattedDate value={rowData.expire} />
     </Typography>
    </Stack>
   </TableCell>
   <TableCell>
    <ChangeProtection id={rowData.id} protection={rowData.protection} type="snapshot" />
   </TableCell>
   <TableCell>
    <SnapshotsActions
     rowData={rowData}
     server={rowData.server_id ? getServerNameFromId(rowData.server_id, servers) : ""}
    />
   </TableCell>
  </TableRow>
 );
};

export default SnapshotsTable;
