import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Chip from "@mui/material/Chip";

import { getServerDetails } from "redux/selectors/serversSelector";

const ServiceStatus = ({ service }: { service: string }): ReactElement => {
 const intl = useIntl();

 const { socket } = useContext(AppContext);

 const server = useSelector(getServerDetails);

 const [status, setStatus] = useState<string>("no");

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("server_logs", (value): void => {
    if (value?.length > 0) {
     for (let i = 0; i < value.length; i++) {
      if (server.agentoken === value[i]?.token) {
       switch (service) {
        case "apache2":
         setStatus(value[i]?.apache_ver || "no");
         break;
        case "postfix":
         setStatus(value[i]?.ser_post || "no");
         break;
        case "dovecot":
         setStatus(value[i]?.ser_dove || "no");
         break;
        case "nginx":
         setStatus(value[i]?.nginx_ver || "no");
         break;
        case "mysql":
         setStatus(value[i]?.sql_ver || "no");
         break;
        case "php":
         setStatus(value[i]?.php_ver || "no");
         break;
        case "ftp":
         setStatus(value[i]?.ser_ftp || "no");
         break;
        case "memcache":
         setStatus(value[i]?.ser_memc || "no");
         break;
        case "redis":
         setStatus(value[i]?.ser_redi || "no");
         break;
        case "ssh":
         setStatus(value[i]?.ser_ssh || "no");
         break;
        default:
         setStatus("no");
         break;
       }
      }
     }
    }
   });
  }
  return () => {
   !isNil(socket) && socket.off("server_logs");
  };
 }, [socket, server]);

 return (
  <Chip
   size="small"
   label={intl.formatMessage({
    id: status === "no" ? "server.services.serviceOff" : "server.services.serviceOn"
   })}
   color={status === "no" ? "error" : "success"}
  />
 );
};

export default ServiceStatus;
