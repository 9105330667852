import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Typography from "@mui/material/Typography";

import RenderPerformance from "components/shared/progressBar/RenderPerformance";

import { getServerDetails } from "redux/selectors/serversSelector";

const ServiceCpu = ({ service }: { service: string }): ReactElement => {
 const { socket } = useContext(AppContext);

 const server = useSelector(getServerDetails);

 const [cpu, setCpu] = useState<number>(0);

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("server_logs", (value): void => {
    if (value?.length > 0) {
     for (let i = 0; i < value.length; i++) {
      if (server.agentoken === value[i]?.token) {
       switch (service) {
        case "apache2":
         setCpu(parseInt(value[i]?.cpu_apache));
         break;
        case "nginx":
         setCpu(parseInt(value[i]?.cpu_nginx));
         break;
        case "mysql":
         setCpu(parseInt(value[i]?.cpu_mysql));
         break;
        case "php":
         setCpu(parseInt(value[i]?.cpu_phpengine));
         break;
        default:
         break;
       }
      }
     }
    }
   });
  }
  return () => {
   !isNil(socket) && socket.off("server_logs");
  };
 }, [socket, server]);

 return service === "apache" || service === "nginx" || service === "sql" || service === "php" ? (
  <RenderPerformance value={cpu} label="server.cpu" width="80%" justify="flex-start" />
 ) : (
  <Typography fontStyle="italic" variant="caption">
   <FormattedMessage id="server.services.cpuNotAvailable" />
  </Typography>
 );
};

export default ServiceCpu;
