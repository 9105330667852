import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postChangePassword } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

const ChangePassword = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, formState, getValues } = useForm({
  defaultValues: {
   oldPassword: "",
   newPassword: "",
   repeatedPassword: ""
  }
 });

 const onSubmit: SubmitHandler<{
  oldPassword: string;
  newPassword: string;
  repeatedPassword: string;
 }> = async (data: { oldPassword: string; newPassword: string; repeatedPassword: string }) => {
  setIsLoading(true);
  await dispatch(postChangePassword(data.oldPassword, data.newPassword, data.repeatedPassword));
  setIsLoading(false);
  setOpenModal(false);
 };

 return (
  <>
   <Button onClick={handleOpen} variant="kxActionButton">
    <FormattedMessage id="profile.updatePassword" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit(onSubmit)}
    title={intl.formatMessage({ id: "profile.updatePassword" })}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Controller
      name="oldPassword"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "profile.oldPassword" })}
        type="password"
        error={formState.isDirty && !!formState?.errors?.oldPassword}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.oldPassword &&
         intl.formatMessage({ id: "profile.oldPasswordNotValid" })
        }
       />
      )}
     />
     <Controller
      name="newPassword"
      control={control}
      rules={{
       required: true,
       minLength: 8
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "profile.newPassword" })}
        type="password"
        error={formState.isDirty && !!formState?.errors?.newPassword}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.newPassword &&
         intl.formatMessage({ id: "login.passwordMinLength" })
        }
       />
      )}
     />
     <Controller
      name="repeatedPassword"
      control={control}
      rules={{
       required: true,
       minLength: 8,
       validate: () => getValues("newPassword") === getValues("repeatedPassword")
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "login.repeatField" })}
        type="password"
        error={formState.isDirty && !!formState?.errors?.repeatedPassword}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.repeatedPassword &&
         intl.formatMessage({ id: "login.invalidPasswords" })
        }
       />
      )}
     />
    </form>
   </AppModal>
  </>
 );
};

export default ChangePassword;
