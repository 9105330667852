import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import ClickToCopy from "components/shared/clipboardCopy/ClickToCopy";

import { getCloudboxDataset } from "redux/selectors/cloudboxSelector";

import ChangePassword from "../actions/ChangePassword";
import UpdateCloudboxName from "../actions/UpdateCloudboxName";

const CloudboxDetails = (): ReactElement => {
 const params = useParams<{ id: string; section: string }>();
 const cloudboxData = useSelector(getCloudboxDataset).find(
  (element) => element.id === Number(params.id)
 );

 const [showPassword, setShowPassword] = useState<string>("password");

 return (
  <>
   <Alert severity="info">
    <FormattedMessage id="addons.cloudbox.detailsInfo" />
   </Alert>
   <Stack
    direction={"row"}
    alignItems="center"
    justifyContent="space-between"
    pt={2}
    pr={1}
    py={0.5}>
    <Stack>
     <Typography noWrap variant="subtitle1" px={1} fontWeight="bold">
      <FormattedMessage id="addons.cloudbox.name" />
     </Typography>
    </Stack>

    <Stack direction="row" alignItems="center" pr={2}>
     <ClickToCopy text={cloudboxData?.name || ""}>
      <Typography variant="subtitle2">{cloudboxData?.name || "N/A"}</Typography>
     </ClickToCopy>
     {cloudboxData && <UpdateCloudboxName cloudbox={cloudboxData} />}
    </Stack>
   </Stack>

   <Divider />
   <Stack direction={"row"} alignItems="center" pr={1} py={0.5} justifyContent="space-between">
    <Stack>
     <Typography noWrap variant="subtitle1" px={1} fontWeight="bold">
      <FormattedMessage id="addons.cloudbox.password" />
     </Typography>
    </Stack>

    <Stack direction="row" alignItems="center" pr={2}>
     <TextField
      value={cloudboxData?.password || ""}
      type={showPassword}
      size="small"
      variant="standard"
      sx={{ width: 200 }}
      InputProps={{
       endAdornment: (
        <InputAdornment position="end">
         <IconButton
          aria-label="toggle password visibility"
          onClick={() => {
           setShowPassword(showPassword === "text" ? "password" : "text");
          }}
          edge="end">
          {showPassword ? <VisibilityOff /> : <Visibility />}
         </IconButton>
        </InputAdornment>
       ),
       readOnly: true
      }}
     />
     {cloudboxData && <ChangePassword id={cloudboxData.id} password={cloudboxData.password} />}
    </Stack>
   </Stack>

   <Divider />
   <Stack direction={"row"} alignItems="center" pr={1} py={0.5} justifyContent="space-between">
    <Stack>
     <Typography noWrap variant="subtitle1" px={1} fontWeight="bold">
      <FormattedMessage id="addons.cloudbox.login" />
     </Typography>
    </Stack>

    <Stack direction="row" alignItems="center" pr={2}>
     <ClickToCopy text={cloudboxData?.login || ""}>
      <Typography variant="subtitle2">{cloudboxData?.login || "N/A"}</Typography>
     </ClickToCopy>
    </Stack>
   </Stack>

   <Divider />
   <Stack direction={"row"} alignItems="center" pr={1} py={0.5} justifyContent="space-between">
    <Stack>
     <Typography noWrap variant="subtitle1" px={1} fontWeight="bold">
      <FormattedMessage id="addons.cloudbox.server" />
     </Typography>
    </Stack>

    <Stack direction="row" alignItems="center" pr={2}>
     <ClickToCopy text={cloudboxData?.login + ".onthecloud.srl" || ""}>
      <Typography variant="subtitle2">
       {cloudboxData?.login + ".onthecloud.srl" || "N/A"}
      </Typography>
     </ClickToCopy>
    </Stack>
   </Stack>
  </>
 );
};

export default CloudboxDetails;
