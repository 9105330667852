import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";
import userReducer from "redux/reducers/userReducer";

import { IAuthApi, IUserApi, IUsersList } from "types/api/userApiInterface";
import { IProfile } from "types/redux/userInterfaces";

import { ApiService } from "service/ApiService";

export const postLogin =
 (email: string, password: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   //da inserire quando si applica la modifica dell'ApiService
   //const ApiData = { url: "/auth", method: "POST", params: { email, password } };
   const { data, status } = await ApiService<IAuthApi>("/auth/login", "POST", { email, password });
   const token = data?.token;
   if (!token || status !== 200) return false; // TODO: handle error with no token
   // Save token in local storage
   localStorage.setItem("jwt", token);
   // Call user info
   await dispatch(getMe());
   return true;
  } catch (error) {
   return false;
  }
 };

export const getMe = (): AppAction<Promise<void>> => async (dispatch) => {
 try {
  const { data, status } = await ApiService<IUserApi>("/users/me", "GET");
  status === 200 && dispatch(userReducer.actions.setUser(data));
  localStorage.setItem("language", data?.language || "it");
 } catch (error) {
  localStorage.removeItem("jwt");
  console.warn("FAILED TO GET USER INFO");
 }
};

export const logoutAction = (): AppAction<void> => (dispatch) => {
 localStorage.removeItem("jwt");
 dispatch(userReducer.actions.resetUser());
};

export const postConfirmContract = (): AppAction<Promise<void>> => async (dispatch) => {
 try {
  await ApiService("/user/editprofiledata", "POST", {
   contract_accepted: true
  });
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to change contract value - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
 }
};

export const postRegistration =
 (
  email: string,
  password: string,
  name: string,
  surname: string,
  repeatPassword: string
 ): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { status } = await ApiService("/auth/register", "POST", {
    email: email,
    password: password,
    firstname: name,
    lastname: surname,
    password2: repeatPassword
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Registration successful",
     timestamp: Date.now(),
     type: "success"
    })
   );
   if (status !== 201) return false; // TODO: handle error for status !== 200
   return true;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to register - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const resetAction =
 (email: string): AppAction<Promise<number>> =>
 async (dispatch) => {
  try {
   const { status } = await ApiService("/user/forgetpwd", "POST", { email: email });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Recover email sent",
     timestamp: Date.now(),
     type: "success"
    })
   );
   return status;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to send recover mail - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return 400;
  }
 };

export const getConfirmEmail =
 (token: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { status } = await ApiService("/verify", "POST", { token: token });
   if (status)
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Registration successful",
      timestamp: Date.now(),
      type: "success"
     })
    );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to register - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const hashAction = async (hash: string): Promise<boolean> => {
 try {
  await ApiService("/users/resetpass", "POST", { hash: hash });
  return true;
 } catch (error) {
  console.warn("FAILED TO VERIFY HASH");
  return false;
 }
};

export const resetPasswordAction =
 (hash: string, password: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   await ApiService("/user/resetpwd", "POST", { token: hash, password: password });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Password changed succesfully",
     timestamp: Date.now(),
     type: "success"
    })
   );
   return true;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change password - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const postChangeLanguage =
 (language: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/user/changelang", "POST", { language });
   await dispatch(getMe());
  } catch (error) {
   console.warn("FAILED TO CHANGE LANGUAGE");
  }
 };

export const postChangeProfile =
 (data: IProfile): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/user/editprofiledata", "POST", {
    firstname: data.firstname,
    lastname: data.lastname,
    companyName: data.companyName,
    email: data.email,
    address: data.street,
    city: data.city,
    country: data.country,
    partitaIVA: data.vatCode,
    zip: data.zipCode,
    telephone: data.phone,
    sdi: data.sdi,
    pec: data.pec
   });
   await dispatch(getMe());
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Changing user data",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change user data - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postChangePassword =
 (oldPassword: string, newPassword: string, newPassword2: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/user/changepassword", "POST", {
    oldPassword: oldPassword,
    newPassword: newPassword,
    newPassword2: newPassword2
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing user password",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change password - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postChangeProfilePicture = async (avatar: string): Promise<void> => {
 try {
  await ApiService("/users/propicupdate", "POST", { propic: avatar });
 } catch (error) {
  console.warn("FAILED TO CHANGE PROFILE PICTURE");
 }
};

export const getUsersList = (): AppAction<Promise<Array<IUsersList>>> => async (dispatch) => {
 try {
  const { data } = await ApiService<{ users: Array<IUsersList> }>("/user/list");
  return data.users;
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to load users - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
  return [];
 }
};

export const postLogAsClient =
 (userid: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data, status } = await ApiService("/user/adminauth", "POST", {
    userid: userid
   });
   status === 200 && localStorage.setItem("jwt", data?.token || "not provided");
   status === 200 && localStorage.setItem("oldId", data?.oldUserId || "not provided");
  } catch (error: any) {
   localStorage.removeItem("jwt");
   localStorage.removeItem("oldId");
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load backups - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRestoreAsClient =
 (userid: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data, status } = await ApiService("/user/restore", "POST", {
    userid: userid
   });
   status === 200 && localStorage.setItem("jwt", data?.token || "not provided");
   localStorage.removeItem("oldId");
  } catch (error: any) {
   localStorage.removeItem("jwt");
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load backups - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const generatePassword = async (length?: number): Promise<string> => {
 try {
  const { data } = await ApiService<string>("/user/generatepwd", "POST", { length: length || 10 });
  return data;
 } catch (error) {
  console.warn("FAILED TO GENERATE PASSWORD");
  return "";
 }
};
