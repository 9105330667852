import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import WebsitePerformance from "components/websites/WebsitePerformance";
import WebsitesMenu from "components/websites/WebsitesMenu";

import { updateSiteStatus } from "redux/handlers/websitesHandler";

import { getIspList } from "redux/selectors/ispSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { websitesTableData } from "types/global/table";

const WebsitesTable = ({
 rowData,
 index,
 performance
}: {
 rowData: websitesTableData;
 index: number;
 performance: Array<{ site: string; size: string; logs: string }>;
}): ReactElement => {
 const dispatch = useAppDispatch();
 const navigate = useNavigate();

 const ispList = useSelector(getIspList);

 const [check, setCheck] = useState<boolean>(rowData.active === "y");

 useEffect(() => {
  setCheck(rowData.active === "y");
 }, [rowData.active]);

 const isp = ispList.find((isp) => isp.id === rowData.isp_id);

 const handleRedirectToSiteDetails = (id: number, active: string): void => {
  active === "y" && navigate(`/app/sites/${id}/details`);
 };

 const handleSwitchToggle = async (isp_Id: number, domain_id: number) => {
  setCheck(!check);
  await dispatch(updateSiteStatus(isp_Id, domain_id));
 };

 return (
  <TableRow
   key={`sites-list-${index}`}
   sx={{ cursor: "pointer" }}
   onClick={() => handleRedirectToSiteDetails(rowData.id, rowData.active)}>
   <TableCell>
    <Stack direction="row" alignItems="center" spacing={1}>
     <Typography variant="subtitle2">
      {rowData.active === "y" ? (
       <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
      ) : (
       <IconSelector icon="StatusIcon" props={{ style: { color: "red" }, fontSize: "small" }} />
      )}
     </Typography>
    </Stack>
   </TableCell>
   <TableCell>
    <Stack direction="row" alignItems="center" justifyContent="left" spacing={0.5}>
     <Typography variant="subtitle2">{rowData.site}</Typography>
    </Stack>
   </TableCell>
   <TableCell>
    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
     <Typography variant="subtitle2">{isp ? isp.hostname : "N/A"}</Typography>
    </Stack>
   </TableCell>
   <TableCell align="right">
    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
     <Typography variant="subtitle2">{rowData.php}</Typography>
    </Stack>
   </TableCell>
   <TableCell>
    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
     <Typography variant="subtitle2">{rowData.php_version}</Typography>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row" onClick={(event) => event.stopPropagation()}>
    <Stack direction="row" justifyContent="center" spacing={0}>
     <Switch
      checked={check}
      onClick={() => handleSwitchToggle(rowData.isp_id, rowData.domain_id)}
     />
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <WebsitePerformance site={rowData.site} performance={performance} />
   </TableCell>
   <TableCell onClick={(event) => event.stopPropagation()}>
    <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
     <WebsitesMenu section="websites" isp_id={rowData.isp_id} site={rowData.site} />
    </Stack>
   </TableCell>
  </TableRow>
 );
};

export default WebsitesTable;
