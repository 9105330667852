import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import AppModal from "components/shared/modal/AppModal";

import { postCreateSite } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

const DockerCreateWebsite = ({ id, proxy_id }: { id: number; proxy_id: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [website, setWebsite] = useState<string>("");

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postCreateSite(id, website, proxy_id));
  handleClose();
  setLoading(false);
 };

 const checkDisabled = (): boolean => {
  return website === "";
 };

 return (
  <>
   <Button onClick={handleOpen} variant="kxActionButton">
    <FormattedMessage id="docker.website.addSite" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.addSite" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || checkDisabled()}>
    <Stack spacing={2}>
     <TextField
      autoComplete="off"
      label={intl.formatMessage({ id: "docker.website.siteName" })}
      InputLabelProps={{ shrink: true }}
      onChange={({ currentTarget }) => setWebsite(currentTarget.value)}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default DockerCreateWebsite;
