import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { formatBytes } from "helpers/numberFormatting";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import { emailValidation } from "constants/regexp";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postEditMailbox } from "redux/handlers/mailsHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IMailbox } from "types/api/mailApiInterface";

const EditMailbox = ({
 selectedMailbox,
 mailDomain
}: {
 selectedMailbox: IMailbox;
 mailDomain: string;
}) => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);

 const handleOpen = () => {
  setValue("name", selectedMailbox.email);
  setValue("description", selectedMailbox.name);
  setValue("quota", formatBytes(selectedMailbox.quota, "MB"));
  setValue("cc", selectedMailbox.cc);
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, setValue, formState } = useForm({
  defaultValues: {
   name: selectedMailbox.email,
   description: selectedMailbox.name,
   quota: formatBytes(selectedMailbox.quota, "MB"),
   cc: selectedMailbox.cc
  }
 });

 const onSubmit: SubmitHandler<{
  name: string;
  description: string;
  quota: number;
  cc: string;
 }> = async (data: { name: string; description: string; quota: number; cc: string }) => {
  setIsLoading(true);
  await dispatch(
   postEditMailbox(
    selectedMailbox.id,
    selectedMailbox.isp_id,
    data.name,
    selectedMailbox.password || "",
    data.quota,
    selectedMailbox.disabledeliver,
    selectedMailbox.disableimap,
    selectedMailbox.disablesmtp,
    data.cc,
    mailDomain
   )
  );
  setIsLoading(false);
  setOpenModal(false);
 };

 return (
  <>
   <Stack>
    <Tooltip title={<FormattedMessage id="mails.edit.mailbox" />}>
     <Button variant="kxActionButton" onClick={handleOpen}>
      <IconSelector icon="EditIcon" />
     </Button>
    </Tooltip>
   </Stack>
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    title={intl.formatMessage({ id: "mails.mailboxOperations" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}>
    <Stack>
     <Controller
      name="name"
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "mails.mailboxName" })}
        error={formState.isDirty && !!formState?.errors?.name}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="new-password"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.name &&
         intl.formatMessage({ id: "mails.mailboxNameError" })
        }
       />
      )}
     />
     <Controller
      name="description"
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "mails.mailboxesDescription" })}
        error={formState.isDirty && !!formState?.errors?.name}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="new-password"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.name &&
         intl.formatMessage({ id: "mails.mailboxesDescriptionError" })
        }
       />
      )}
     />
     <Controller
      name="quota"
      control={control}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "mails.mailboxesQuota" })}
        error={formState.isDirty && !!formState?.errors?.name}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="new-password"
        type="number"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.name &&
         intl.formatMessage({ id: "mails.mailboxesQuotaError" })
        }
       />
      )}
     />
     <Controller
      name="cc"
      control={control}
      rules={{
       pattern: emailValidation
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "mails.mailboxesCc" })}
        error={formState.isDirty && !!formState?.errors?.name}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="new-password"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.name &&
         intl.formatMessage({ id: "mails.mailboxesCcError" })
        }
       />
      )}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default EditMailbox;
