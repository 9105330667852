import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import AddIcon from "@mui/icons-material/Add";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { emailValidation } from "constants/regexp";

import AppModal from "components/shared/modal/AppModal";

import { postAddTeamsUser } from "redux/handlers/teamsHandler";

import { getUserId } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

type userType = {
 email: string;
 password: string;
};

const AddTeamsUser = (): ReactElement => {
 const intl = useIntl();
 const userId: number = useSelector(getUserId);
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);

 const { control, handleSubmit, formState, reset } = useForm({
  defaultValues: {
   email: "",
   password: ""
  }
 });

 const onSubmit: SubmitHandler<userType> = async (data: userType) => {
  setIsLoading(true);
  await dispatch(postAddTeamsUser(userId, data.email, data.password));
  setIsLoading(false);
  setOpenModal(false);
 };

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };

 const handleClose = () => {
  setOpenModal(false);
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen} endIcon={<AddIcon />}>
    <FormattedMessage id="teams.action.add" />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit(onSubmit)}
    title={intl.formatMessage({ id: "teams.action.add" })}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Controller
      name="email"
      control={control}
      rules={{
       required: true,
       pattern: emailValidation
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "teams.email" })}
        error={formState.isDirty && !!formState?.errors?.email}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="new-password"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.email &&
         intl.formatMessage({ id: "teams.emailError" })
        }
       />
      )}
     />
     <Stack direction={"row"} spacing={2}>
      <Controller
       name="password"
       control={control}
       rules={{
        required: true,
        minLength: 8
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         type="password"
         autoComplete="new-password"
         label={intl.formatMessage({ id: "teams.password" })}
         error={formState.isDirty && !!formState?.errors?.password}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         helperText={
          formState.isDirty &&
          !!formState?.errors?.password &&
          intl.formatMessage({ id: "teams.passwordError" })
         }
        />
       )}
      />
      <Button variant="kxActionButton">
       <FormattedMessage id="teams.generatePassword" />
      </Button>
     </Stack>
    </form>
   </AppModal>
  </>
 );
};

export default AddTeamsUser;
