export type serverData = {
 id: number;
 hz_id: number;
 status: string;
 os: string;
 serverName: string;
 ipAddress: string;
 owner: string;
 countryCode: string;
 renewDate: string;
 serverMachine: string;
 price: string;
};

export const linuxOs = ["ubuntu", "debian", "fedora", "rocky", "centos"];
