import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ClickToCopy from "components/shared/clipboardCopy/ClickToCopy";
import AppModal from "components/shared/modal/AppModal";

import { getCloudboxDataset } from "redux/selectors/cloudboxSelector";

const CloudboxCommands = (): ReactElement => {
 const intl = useIntl();
 const params = useParams<{ id: string; section: string }>();
 const cloudboxData = useSelector(getCloudboxDataset).find(
  (element) => element.id === Number(params.id)
 );

 const [command, setCommand] = useState<string>("");
 const [open, setOpen] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleSetCommand = (type: string): void => {
  switch (type) {
   case "windows":
    setCommand(
     `net use s: \\\\${cloudboxData?.login}.onthecloud.srl\\backup /user:${cloudboxData?.login} ${cloudboxData?.password} /persistent:Yes`
    );
    break;
   case "linux":
    setCommand(
     `//${cloudboxData?.login}.onthecloud.srl./backup /mnt/backup-server cifs iocharset=utf8,rw,credentials=/etc/backup-credentials.txt,uid=1000,gid=1000,file_mode=0660,dir_mode=0770 0 0`
    );
    break;
   case "linux2":
    setCommand(
     `mount.cifs -o user=${cloudboxData?.login},pass=${cloudboxData?.password} //${cloudboxData?.login}.onthecloud.srl./backup /PATH/FOLDER`
    );
    break;
   default:
    setCommand("");
    break;
  }

  handleOpen();
 };

 return (
  <>
   <Alert severity="info">
    <FormattedMessage id="addons.cloudbox.commandsInfo" />
   </Alert>
   <Stack direction={"row"} alignItems="center" py={0.5} justifyContent="space-between">
    <Stack>
     <Typography noWrap variant="subtitle1" px={1} fontWeight="bold">
      <FormattedMessage id="addons.cloudbox.windowsCommand" />
     </Typography>
    </Stack>

    <Stack direction="row" alignItems="center" pr={2}>
     {cloudboxData && (
      <Button variant="kxActionButton" onClick={() => handleSetCommand("windows")}>
       <FormattedMessage id="addons.cloudbox.showCommand" />
      </Button>
     )}
    </Stack>
   </Stack>

   <Divider />
   <Stack direction={"row"} alignItems="center" py={0.5} justifyContent="space-between">
    <Stack>
     <Typography noWrap variant="subtitle1" px={1} fontWeight="bold">
      <FormattedMessage id="addons.cloudbox.linuxFstabCommand" />
     </Typography>
    </Stack>

    <Stack direction="row" alignItems="center" pr={2}>
     {cloudboxData && (
      <Button variant="kxActionButton" onClick={() => handleSetCommand("linux")}>
       <FormattedMessage id="addons.cloudbox.showCommand" />
      </Button>
     )}
    </Stack>
   </Stack>

   <Divider />
   <Stack direction={"row"} alignItems="center" py={0.5} justifyContent="space-between">
    <Stack>
     <Typography noWrap variant="subtitle1" px={1} fontWeight="bold">
      <FormattedMessage id="addons.cloudbox.linuxMountCommand" />
     </Typography>
    </Stack>

    <Stack direction="row" alignItems="center" pr={2}>
     {cloudboxData && (
      <Button variant="kxActionButton" onClick={() => handleSetCommand("linux2")}>
       <FormattedMessage id="addons.cloudbox.showCommand" />
      </Button>
     )}
    </Stack>
   </Stack>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "addons.cloudbox.showCommand" })}
    handleClose={handleClose}
    handleConfirm={handleClose}
    showConfirmButton={false}>
    <Stack direction="row">
     <Typography>{command}</Typography>
     <ClickToCopy text={command} />
    </Stack>
   </AppModal>
  </>
 );
};

export default CloudboxCommands;
