import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { emailValidation } from "constants/regexp";

import AppModal from "components/shared/modal/AppModal";

import { putUpdateTeamsUser } from "redux/handlers/teamsHandler";

import { getUserId } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

const UpdateTeamsUser = ({ id, userMail }: { id: number; userMail: string }): ReactElement => {
 const intl = useIntl();
 const userId: number = useSelector(getUserId);
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);

 const { control, handleSubmit, formState, reset } = useForm({
  defaultValues: {
   email: userMail
  }
 });

 const onSubmit: SubmitHandler<{ email: string }> = async (data: { email: string }) => {
  setIsLoading(true);
  await dispatch(putUpdateTeamsUser(id, data.email, userId));
  setIsLoading(false);
  setOpenModal(false);
 };

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };

 const handleClose = () => {
  setOpenModal(false);
 };

 return (
  <>
   <Typography onClick={handleOpen}>
    <FormattedMessage id="teams.updateTeamsUser" />
   </Typography>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit(onSubmit)}
    title={intl.formatMessage({ id: "teams.updateTeamsUser" })}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Controller
      name="email"
      control={control}
      rules={{
       required: true,
       pattern: emailValidation
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
         e.stopPropagation();
        }}
        label={intl.formatMessage({ id: "teams.email" })}
        error={formState.isDirty && !!formState?.errors?.email}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.email &&
         intl.formatMessage({ id: "teams.emailError" })
        }
       />
      )}
     />
    </form>
   </AppModal>
  </>
 );
};

export default UpdateTeamsUser;
