import { cloudboxTypes } from "constants/cloudboxType";
import { ipRegex } from "constants/regexp";

import { IAllServers } from "types/api/serversApiInterface";
import { serverData } from "types/global/server";

export const getServerFromId = (id: number, serverList: Array<serverData>): string => {
 const serverFiltered = serverList.filter((server) => {
  if (!server.hz_id || server.hz_id !== id) return false;

  return true;
 });

 if (serverFiltered[0]?.serverName) return serverFiltered[0]?.serverName;
 else return "";
};

export const getServerNameFromId = (id: number, serverList: Array<IAllServers>): string => {
 const serverFiltered = serverList.filter((server) => {
  if (!server.server_id || server.server_id !== id) return false;

  return true;
 });

 if (serverFiltered[0]?.server_name) return serverFiltered[0]?.server_name;
 else return "";
};

export const getServersNamesList = (servers: Array<serverData>): Array<string> => {
 return servers.map((server) => {
  if (server.serverName) return server.serverName;
  else return "";
 });
};

export const getCloudboxUpgrades = (
 type: string
): Array<{ type: string; size: string; snapshots: number; price: number }> => {
 const filteredUpgrade = cloudboxTypes.filter((element) => {
  if (element.type === type) return false;
  if (parseInt(element.type[2]) <= parseInt(type[2])) return false;

  return true;
 });

 return filteredUpgrade;
};

export const checkIPFormat = (ip: string): boolean => {
 return ipRegex.test(ip) || ip === "0.0.0.0/0" || ip === "::/0";
};

export const getServersListFromIds = (
 servers: Array<serverData>,
 ids: Array<number>
): Array<{ label: string; hz_id: number }> => {
 const serverFiltered = servers.filter((server) => ids.includes(server?.hz_id || -1));

 return serverFiltered.map((server) => {
  if (server.serverName && server.hz_id) return { label: server.serverName, hz_id: server.hz_id };
  else return { label: "", hz_id: 0 };
 });
};

export const formatNetworkGateway = (ip: string): string => {
 return `${ip.substring(0, ip.length - 1)}1`;
};

export const formatIpRange = (ip: string): string => {
 return ip.substring(0, ip.length - 3);
};

export const checkPort = (port: string): boolean => {
 if (port.includes("-")) {
  const ports = port.split("-");
  let result = false;
  for (let singlePort of ports) {
   if (!parseInt(singlePort)) {
    result = true;
   }
  }
  return result;
 } else if (port === "any") {
  return false;
 } else {
  if (parseInt(port)) {
   return false;
  } else {
   return true;
  }
 }
};
