import React, { createContext, useEffect, useState } from "react";
import { Socket } from "socket.io-client";

import { createSocket } from "./socket";

export type AppContextType = {
 test: string;
 socket: Socket | null;
};

export const useApp = (userLogged: boolean): AppContextType => {
 const [socket, setSocket] =
  // @ts-ignore
  useState<Socket>(null);

 useEffect(() => {
  if (!userLogged) return;

  const socket = createSocket();
  setSocket(socket);

  function onConnect(): void {
   console.log("on connect");
  }

  function onDisconnect(): void {
   console.log("on disconnect");
  }

  socket.on("server", (value): void => {});
  socket.on("app_notification", (value): void => {});
  socket.on("server_logs", (value): void => {});
  socket.on("server_jobs", (value): void => {});
  socket.on("server_data", (value): void => {});
  socket.on("server_orders", (value): void => {});
  socket.on("domain_data", (value): void => {});
  socket.on("domain_orders", (value): void => {});
  socket.on("site_logs", (value): void => {});
  socket.on("app_data", (value): void => {});
  socket.on("app_orders", (value): void => {});
  socket.on("addons_data", (value): void => {});
  socket.on("profile_data", (value): void => {});
  socket.on("maildomain_logs", (value): void => {});
  socket.on("mailbox_logs", (value): void => {});
  socket.on("barmetal_data", (value): void => {});
  socket.on("app_notify", (value): void => {});
  socket.on("gateway_orders", (value): void => {});
  socket.on("manage_gateway", (value): void => {});
  socket.on("chat_ai", (value): void => {});
  socket.on("docker_order", (value): void => {});
  socket.on("docker_data", (value): void => {});

  socket.on("connect", onConnect);
  socket.on("disconnect", onDisconnect);

  return (): void => {
   socket.off("connect", onConnect);
   socket.off("disconnect", onDisconnect);
  };
 }, [userLogged]);

 return {
  test: "PROVA",
  socket: socket
 };
};

export const AppContext: React.Context<AppContextType> = createContext({
 test: "",
 socket: null
} as AppContextType);
