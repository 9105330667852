/* eslint-disable react/style-prop-object */
import React, { ReactElement, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { contacts } from "constants/domains";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getContactsList, postChangeDomainOwners } from "redux/handlers/domainsHandler";

import { getDomainsList } from "redux/selectors/domainsSelector";
import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IContactData } from "types/api/domainsApiInterface";

type contactData = {
 admin: number;
 billing: number;
 technical: number;
 registrant: number;
};

const DomainChangeOwner = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const userBalance = useSelector(getUserBalance);
 const domainData = useSelector(getDomainsList)[0];

 const [contactsList, setContactsList] = useState<Array<IContactData>>([]);
 const [loading, setLoading] = useState<boolean>(false);
 const [open, setOpen] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 useEffect(() => {
  (async () => {
   setLoading(true);
   setContactsList(await getContactsList());
   setLoading(false);
  })();
 }, []);

 const { control, handleSubmit, watch, setValue } = useForm({
  defaultValues: {
   registrant: 0,
   admin: 0,
   billing: 0,
   technical: 0
  }
 });

 const onSubmit: SubmitHandler<contactData> = async (data: contactData) => {
  setLoading(true);
  await dispatch(
   postChangeDomainOwners(domainData.id, data.registrant, data.technical, data.admin, data.billing)
  );
  setLoading(false);
  handleClose();
 };

 const checkDisabled = (): boolean => {
  return (
   watch("registrant") === 0 ||
   watch("admin") === 0 ||
   watch("billing") === 0 ||
   watch("technical") === 0
  );
 };

 return (
  <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
   <Stack>
    <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} pt={1}>
     <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
     <Typography variant="h6" component="h2" ml={1}>
      <FormattedMessage id="domains.owners" />{" "}
     </Typography>
    </Stack>
    <Stack p={2}>
     {loading ? (
      <CircularProgress />
     ) : (
      <Stack spacing={2}>
       <Alert severity="info" sx={{ mb: 2 }}>
        <FormattedMessage id="domain.changeOwnerMessage" />
       </Alert>
       <Divider />
       <Stack>
        <Stack direction="row" spacing={1}>
         <Typography fontWeight="bold" variant="subtitle2">
          <FormattedMessage id="domain.add.registrant" />
         </Typography>
         <Typography variant="subtitle2">
          {domainData.contactRegistrant.includes("null")
           ? "Not available"
           : domainData.contactRegistrant}
         </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
         <Typography fontWeight="bold" variant="subtitle2">
          <FormattedMessage id="domain.add.admin" />
         </Typography>
         <Typography variant="subtitle2">
          {domainData.contactAdmin.includes("null") ? "Not available" : domainData.contactAdmin}
         </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
         <Typography fontWeight="bold" variant="subtitle2">
          <FormattedMessage id="domain.add.billing" />
         </Typography>
         <Typography variant="subtitle2">
          {domainData.contactBilling.includes("null") ? "Not available" : domainData.contactBilling}
         </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
         <Typography fontWeight="bold" variant="subtitle2">
          <FormattedMessage id="domain.add.technical" />
         </Typography>
         <Typography variant="subtitle2">
          {domainData.contactTechnical.includes("null")
           ? "Not available"
           : domainData.contactTechnical}
         </Typography>
        </Stack>
       </Stack>
       <Divider />
       {contacts.map((data, index) => {
        return (
         <Stack key={`contacts-autocomplete-${index}`}>
          <Controller
           name={data.name}
           control={control}
           rules={{
            required: true,
            validate: () => watch(data.name) !== 0
           }}
           render={({ field }) => (
            <Autocomplete
             fullWidth={true}
             autoHighlight
             sx={{ my: 2 }}
             onChange={(e, value) =>
              isNil(value) ? setValue(data.name, 0) : setValue(data.name, value.id)
             }
             options={contactsList.map((element) => {
              return { label: `${element.firstname} ${element.lastname}`, id: element.id };
             })}
             renderOption={(props, option) => {
              return (
               <li {...props} key={`first-ns-${option.id}`}>
                {option.label}
               </li>
              );
             }}
             renderInput={(params) => (
              <TextField
               {...params}
               {...field}
               onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                e.stopPropagation();
               }}
               label={<FormattedMessage id={data.label} />}
               InputLabelProps={{ shrink: true }}
              />
             )}
            />
           )}
          />
         </Stack>
        );
       })}
       <Divider />
       <Stack spacing={2} mt={2}>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
         <Typography>
          <FormattedMessage id="domain.recap.price" />
         </Typography>
         <Chip
          color="primary"
          label={
           <Typography>
            <FormattedNumber value={2} style={"currency"} currency="EUR" />
           </Typography>
          }
         />
        </Stack>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
         <Typography>
          <FormattedMessage id="domain.recap.balance" />
         </Typography>
         <Chip
          color={userBalance < 2 ? "error" : "success"}
          label={
           <Typography>
            <FormattedNumber value={userBalance} style={"currency"} currency="EUR" />
           </Typography>
          }
         />
        </Stack>
       </Stack>
       {userBalance < 2 && (
        <Alert severity="error">
         <FormattedMessage id="domain.balanceNotSufficient" />
        </Alert>
       )}
       <Stack direction="row" justifyContent="flex-start">
        <Button onClick={handleOpen} disabled={checkDisabled() || loading}>
         Conferma
        </Button>
        <AppModal
         open={open}
         close={handleClose}
         title={intl.formatMessage({ id: "domains.changeOwn" })}
         handleClose={handleClose}
         disabled={userBalance < 2 || checkDisabled() || loading}
         handleConfirm={handleSubmit(onSubmit)}>
         <Typography>
          <FormattedMessage id="domain.owner.changeOwnerMessage" />
         </Typography>
        </AppModal>
       </Stack>
      </Stack>
     )}
    </Stack>
   </Stack>
  </Paper>
 );
};

export default DomainChangeOwner;
