import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { mailsLinks } from "constants/staticLinks";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import MobileSectionMenu from "components/shared/sideMenu/MobileSectionMenu";
import SectionMenu from "components/shared/sideMenu/SectionMenu";

import { getAllMailsDomain } from "redux/handlers/mailsHandler";
import { postSyncronizeSocket } from "redux/handlers/socketHandler";

import { useAppDispatch } from "hooks/reduxHook";

import MailDetailsPage from "./MailDetailsPage";

const MailsManage = (): ReactElement => {
 const theme = useTheme();
 const dispatch = useAppDispatch();
 const { socket } = useContext(AppContext);
 const location = useLocation();

 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));
 const params = useParams<{ mail_id: string; section: string }>();

 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [reloadData, setReloadData] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   await dispatch(getAllMailsDomain(0, 10, 0, location.state.key));
   setIsLoading(false);
   await dispatch(postSyncronizeSocket("mailbox"));
   await dispatch(postSyncronizeSocket("maildomain"));
  })();
 }, []);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("app_data", (value): void => {
    if (value?.completed && value?.section === "mail") {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("app_data");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    dispatch(getAllMailsDomain(0, 10, 0, location.state.key));
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 return !isLoading ? (
  <Container maxWidth="xl">
   <Stack pt={1}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "apps", url: "/app/sites" },
      { name: "mail", url: "/app/mail" },
      { name: params?.section || "", url: location.pathname }
     ]}
    />
   </Stack>
   <Grid container>
    {desktopViewPort ? (
     <Grid xs={desktopViewPort ? 3 : 0}>
      <Stack mt={6}>
       <SectionMenu
        menuList={mailsLinks.map((item) => ({
         name: item.name,
         url: `/app/mail/${params?.mail_id}/${item.url}`,
         icon: item.icon,
         disabled: item.url === "backup"
        }))}
       />
      </Stack>
     </Grid>
    ) : (
     <MobileSectionMenu
      links={mailsLinks}
      disabled={["backup"]}
      url={`/app/mail/${params?.mail_id}`}
     />
    )}
    <Grid xs={desktopViewPort ? 9 : 12} mt={3}>
     <MailDetailsPage />
    </Grid>
   </Grid>
  </Container>
 ) : (
  <></>
 );
};

export default MailsManage;
