import React, { ReactElement, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux/es/exports";
import { NavLink, useParams } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";

import { getAllIspNotPaged } from "redux/handlers/websitesHandler";

import { getIspList } from "redux/selectors/ispSelector";
import { getMailsList } from "redux/selectors/mailsSelector";

import { useAppDispatch } from "hooks/reduxHook";

import MailAutoresponder from "./MailAutoresponder";
import MailDetails from "./MailDetails";
import MailMonitoring from "./MailMonitoring";
import MailMailboxes from "./MailsMailboxes";

const MailDetailsPage = (): ReactElement => {
 const dispatch = useAppDispatch();
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));
 const params = useParams<{ mail_id: string; section: string }>();
 const maildetails = useSelector(getMailsList).find(
  (element) => element.id === Number(params.mail_id)
 );
 const ispList = useSelector(getIspList);

 const [section, setSection] = useState<number>(0);

 const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
  setSection(newValue);
 };

 const RenderTabContent = (): ReactElement => {
  switch (section) {
   case 0:
    return <MailDetails />;
   case 1:
    return <MailMailboxes />;
   case 2:
    return <MailAutoresponder />;
   default:
    return <MailDetails />;
  }
 };

 useEffect(() => {
  (async () => {
   await dispatch(getAllIspNotPaged("mailserver"));
  })();
 }, []);

 return (
  <>
   <Stack mt={5} justifyContent="space-between" direction={desktopViewPort ? "row" : "column"}>
    <Stack>
     <Stack spacing={1} alignItems="flex-start">
      <Typography fontWeight="bold" fontSize={16} ml={1} textTransform="uppercase">
       {maildetails?.domain}
      </Typography>
      <Stack direction="row" spacing={1}>
       <Tooltip title={intl.formatMessage({ id: "sites.details.goToServer" })}>
        <NavLink
         to={`/servers/manage/${ispList.find((element) => element.id === maildetails?.isp_id)
          ?.server_id}/specification`}
         style={{
          textDecoration: "none"
         }}>
         <Stack direction="row" spacing={1} sx={{ cursor: "pointer" }}>
          <IconSelector icon="ServerIcon" props={{ fontSize: "small", color: "primary" }} />
          <Typography variant="subtitle2" color="primary">
           {ispList.find((element) => element.id === maildetails?.isp_id)?.hostname}
          </Typography>
         </Stack>
        </NavLink>
       </Tooltip>
       <Typography>{ispList.find((element) => element.id === maildetails?.isp_id)?.ip1}</Typography>
      </Stack>
     </Stack>
    </Stack>
   </Stack>
   <MailMonitoring />
   <Grid container spacing={2}>
    <Grid xs={12}>
     <Paper
      elevation={0}
      sx={{ mt: 3, pb: 0.7, minHeight: 216, borderRadius: "10px", boxShadow: 0 }}>
      <Tabs
       value={section}
       onChange={handleChangeTab}
       variant={desktopViewPort ? "standard" : "scrollable"}
       scrollButtons={true}
       allowScrollButtonsMobile>
       <Tab label={intl.formatMessage({ id: "mails.details" })} />
       <Tab label={intl.formatMessage({ id: "mails.mailboxes" })} />
       <Tab label={intl.formatMessage({ id: "mails.autoresponder" })} />
      </Tabs>
      <RenderTabContent />
     </Paper>
    </Grid>
   </Grid>
  </>
 );
};

export default MailDetailsPage;
