/* eslint-disable react/style-prop-object */
import React, { ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatServerPrice } from "helpers/server";

import { isNil } from "ramda";

import DvrIcon from "@mui/icons-material/Dvr";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { fqdnRegex } from "constants/regexp";
import { steps } from "constants/server";

import AppModal from "components/shared/modal/AppModal";

import {
 getHetznerLocations,
 getHetznerServers,
 getRebuildOs,
 getServerProvider,
 postBuyServer,
 postCheckServerName
} from "redux/handlers/serverHandler";

import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { ILocations, IOsDetails, IProviders, IServerTypes } from "types/api/serversApiInterface";

const ServerWizard = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const navigate = useNavigate();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const userBalance = useSelector(getUserBalance);

 const [activeStep, setActiveStep] = useState<number>(0);
 const [osList, setOsList] = useState<Array<IOsDetails>>([]);
 const [stepLoading, setStepLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [locationList, setLocationList] = useState<Array<ILocations>>([]);
 const [typesList, setTypesList] = useState<Array<IServerTypes>>([]);
 const [tabValue, setTabValue] = useState<number>(0);
 const [providerList, setProviderList] = useState<Array<IProviders>>([]);
 const [nameChecked, setNameChecked] = useState<string>("");
 const [loading, setLoading] = useState<boolean>(false);
 const [serverType, setServerType] = useState<string>("");

 const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  setTabValue(newValue);
 };

 useEffect(() => {
  (async () => {
   const data = await dispatch(getRebuildOs());
   setOsList(isNil(data) ? [] : data);
   setProviderList(await dispatch(getServerProvider()));
   setIsLoading(false);
  })();
 }, []);

 const { control, formState, reset, watch, getValues, setValue } = useForm({
  defaultValues: {
   name: "",
   provider: 0,
   os: 0,
   location: 0,
   serverType: 0,
   enableIpv4: false,
   enableIpv6: false,
   enableBackup: false
  }
 });

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);
 const handleReset = () => {
  reset();
  setActiveStep(0);
 };

 const getLocationData = async () => {
  setStepLoading(true);
  setLocationList(await dispatch(getHetznerLocations()));
  setStepLoading(false);
 };

 const getServersTypeData = async () => {
  setStepLoading(true);
  setTypesList(
   await dispatch(
    getHetznerServers(
     locationList.find((element) => element.id === getValues("location"))?.name || ""
    )
   )
  );
  setStepLoading(false);
 };

 const handleNext = () => {
  if (activeStep === 3) getLocationData();
  if (activeStep === 4) getServersTypeData();
  if (activeStep === 2 && serverType !== "custom") {
   getLocationData();
   setActiveStep((prevActiveStep) => prevActiveStep + 2);
  } else {
   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }
 };

 const handleBack = () => {
  if (activeStep === 4 && serverType !== "custom") {
   setActiveStep((prevActiveStep) => prevActiveStep - 2);
  } else {
   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }
 };

 const checkServerName = async () => {
  setStepLoading(true);
  setNameChecked(await dispatch(postCheckServerName(watch("name"))));
  setStepLoading(false);
 };

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postBuyServer(
    getValues("location"),
    getValues("os"),
    getValues("name"),
    getValues("provider"),
    typesList.find((element) => element.id === getValues("serverType"))?.name || "",
    getValues("enableIpv4"),
    getValues("enableIpv6"),
    getValues("enableBackup"),
    serverType
   )
  );
  handleClose();
  handleReset();
  setLoading(false);
  navigate("/servers");
 };

 const RenderHetznerServers = () => {
  return (
   <Box sx={{ width: "100%", typography: "body1" }}>
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
     <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
      <Tab label="Standard" />
      <Tab label="Dedicated" />
     </Tabs>
    </Box>
    <Stack
     role="tabpanel"
     hidden={tabValue !== 0}
     id={`simple-tabpanel-${0}`}
     aria-labelledby={`simple-tab-${0}`}>
     {tabValue === 0 && (
      <Controller
       name="serverType"
       control={control}
       render={({ field }) => (
        <RadioGroup
         {...field}
         value={watch("serverType")}
         onChange={(e, value) => setValue("serverType", Number(value))}>
         {typesList
          .filter((element) => !element.name.includes("VFC"))
          .map((element, index) => {
           return (
            <FormControlLabel
             key={`server-type-${index}`}
             value={element.id}
             control={<Radio />}
             sx={{
              borderRadius: 2,
              backgroundColor: "#E5E7E9",
              my: 1,
              "&:hover": {
               backgroundColor: "#CACFD2 "
              }
             }}
             label={`${element.name}  ${element.cores} cores  ${element.memory} GB RAM  ${element.disk} GB Disk`}
            />
           );
          })}
        </RadioGroup>
       )}
      />
     )}
    </Stack>
    <Stack
     role="tabpanel"
     hidden={tabValue !== 1}
     id={`simple-tabpanel-${1}`}
     aria-labelledby={`simple-tab-${1}`}>
     {tabValue === 1 && (
      <Controller
       name="serverType"
       control={control}
       render={({ field }) => (
        <RadioGroup
         {...field}
         value={watch("serverType")}
         onChange={(e, value) => setValue("serverType", Number(value))}>
         {typesList
          .filter((element) => element.name.includes("VFC"))
          .map((element, index) => {
           return (
            <FormControlLabel
             key={`server-type-${index}`}
             value={element.id}
             control={<Radio />}
             sx={{
              borderRadius: 2,
              backgroundColor: "#E5E7E9",
              my: 1,
              "&:hover": {
               backgroundColor: "#CACFD2 "
              }
             }}
             label={`${element.name}  ${element.cores} cores  ${element.memory} GB RAM  ${element.disk} GB Disk`}
            />
           );
          })}
        </RadioGroup>
       )}
      />
     )}
    </Stack>
   </Box>
  );
 };

 const SwitchProviderSever = (): ReactElement => {
  switch (providerList.find((element) => element.id === getValues("provider"))?.name) {
   case "konsolex":
    return !stepLoading ? (
     typesList.length > 0 ? (
      <RenderHetznerServers />
     ) : (
      <FormattedMessage id="server.add.noServerFoundForLocation" />
     )
    ) : (
     <></>
    );
   default:
    return <RenderHetznerServers />;
  }
 };

 const handleSetServer = (type: string) => {
  setServerType(type);
  if (type !== "custom") {
   setValue("os", 6);
  }
 };

 const renderStepContent = (index: number) => {
  switch (index) {
   case 0:
    return (
     <Controller
      name="name"
      control={control}
      rules={{
       required: true,
       pattern: fqdnRegex
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        onChange={(event) => {
         setValue("name", event.target.value);
         setNameChecked("");
        }}
        label={intl.formatMessage({ id: "server.name" })}
        error={formState.isDirty && !!formState?.errors?.name}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="on"
       />
      )}
     />
    );
   case 1:
    return (
     <Controller
      name="provider"
      control={control}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => setValue("provider", value?.id || 0)}
        options={providerList.map((element) => {
         return { label: element.name, id: element.id };
        })}
        value={providerList
         .map((element) => {
          return { label: element.name, id: element.id };
         })
         .find((element) => element.id === getValues("provider"))}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          error={formState.isDirty && !!formState?.errors?.provider}
          label={<FormattedMessage id="server.provider" />}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    );
   case 2:
    return (
     <Autocomplete
      fullWidth={true}
      autoHighlight
      sx={{ my: 2 }}
      onChange={(e, value) => handleSetServer(value || "")}
      options={[
       "custom",
       "webserver-apache",
       "webserver-nginx",
       "mailserver",
       "nameserver",
       "gateway",
       "container-server"
      ]}
      value={serverType}
      renderInput={(params) => (
       <TextField
        {...params}
        label={<FormattedMessage id="server.add.type" />}
        InputLabelProps={{ shrink: true }}
       />
      )}
     />
    );
   case 3:
    return (
     <Controller
      name="os"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => setValue("os", value?.id || 0)}
        options={osList.map((element) => {
         return { label: element.name, id: element.id };
        })}
        value={osList
         .map((element) => {
          return { label: element.name, id: element.id };
         })
         .find((element) => element.id === getValues("os"))}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          error={formState.isDirty && !!formState?.errors?.os}
          label={<FormattedMessage id="server.os" />}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    );
   case 4:
    return (
     <Controller
      name="location"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => {
         setValue("location", value?.id || 0);
         setValue("serverType", 0);
        }}
        options={locationList.map((element) => {
         return { label: element.city, id: element.id };
        })}
        value={locationList
         .map((element) => {
          return { label: element.city, id: element.id };
         })
         .find((element) => element.id === getValues("location"))}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          error={formState.isDirty && !!formState?.errors?.location}
          label={<FormattedMessage id="server.location" />}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    );
   case 5:
    return <SwitchProviderSever />;
   case 6:
    return (
     <Stack>
      <Stack justifyContent="center" alignContent="center" direction="column">
       <Controller
        name="enableIpv4"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Switch
            {...field}
            checked={field.value || false}
            onChange={(e) => field.onChange(e.target.checked)}
           />
          }
          label={<FormattedMessage id="server.add.enableIpv4" />}
         />
        )}
       />
      </Stack>
      <Stack justifyContent="center" alignContent="center" direction="column">
       <Controller
        name="enableIpv6"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Switch
            {...field}
            checked={field.value || false}
            onChange={(e) => field.onChange(e.target.checked)}
           />
          }
          label={<FormattedMessage id="server.add.enableIpv6" />}
         />
        )}
       />
      </Stack>
     </Stack>
    );
   case 7:
    return (
     <Stack>
      <Stack justifyContent="center" alignContent="center" direction="column">
       <Controller
        name="enableBackup"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Switch
            {...field}
            checked={field.value || false}
            onChange={(e) => field.onChange(e.target.checked)}
           />
          }
          label={<FormattedMessage id="server.add.enableBackup" />}
         />
        )}
       />
      </Stack>
     </Stack>
    );
  }
 };

 const disableNextButton = (index: number) => {
  switch (index) {
   case 0:
    return (
     watch("name").length === 0 ||
     !fqdnRegex.test(watch("name")) ||
     nameChecked === "name not available"
    );
   case 1:
    return watch("provider") === 0;
   case 2:
    return serverType === "";
   case 3:
    return watch("os") === 0;
   case 4:
    return watch("location") === 0;
   case 5:
    return watch("serverType") === 0;
   case 6:
    return !watch("enableIpv4") && !watch("enableIpv6");
   default:
    return false;
  }
 };

 const checkDisableConfirm = () => {
  return watch("name") === "" || watch("provider") === 0 || watch("os") === 0;
 };

 const renderServerChecked = () => {
  switch (nameChecked) {
   case "name available":
    return (
     <Alert severity="success">
      <FormattedMessage id="server.add.nameAvailable" />
     </Alert>
    );
   default:
    return (
     <Alert severity="error">
      <FormattedMessage id="server.add.nameNotAvailable" />
     </Alert>
    );
  }
 };

 return !isLoading ? (
  <Stack spacing={2}>
   <Stack p={4} alignItems="center">
    <DvrIcon fontSize="large" />
    <Typography variant="h5">
     <FormattedMessage id="server.newserver" />
    </Typography>
   </Stack>
   <Stack direction={desktopViewPort ? "row" : "column"} justifyContent="center">
    <Box
     sx={{ maxWidth: desktopViewPort ? 1000 : "auto", minWidth: desktopViewPort ? 500 : "auto" }}
     m={10}>
     <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => (
       <Step key={step.label}>
        <StepLabel>
         <FormattedMessage id={step.label} />
        </StepLabel>
        {!stepLoading ? (
         <StepContent>
          <Stack spacing={2}>
           <Alert severity="info">
            <Typography>
             <FormattedMessage id={step.description} />
            </Typography>
           </Alert>
           {renderStepContent(index)}
           {index === 0 && nameChecked !== "" && renderServerChecked()}
          </Stack>
          <Box sx={{ mb: 2 }}>
           <div>
            {index !== steps.length - 1 ? (
             <Button
              disabled={disableNextButton(index)}
              variant="contained"
              onClick={() => (nameChecked === "" ? checkServerName() : handleNext())}
              sx={{ mt: 1, mr: 1 }}>
              {index === 0 && nameChecked === "" ? (
               <FormattedMessage id="server.add.serverCheck" />
              ) : (
               <FormattedMessage id="server.add.continue" />
              )}
             </Button>
            ) : (
             <></>
            )}
            {index === 7 && (
             <>
              <Button
               sx={{ mt: 1, mr: 1 }}
               variant="contained"
               disabled={checkDisableConfirm()}
               onClick={handleOpen}>
               <FormattedMessage id="server.add.confirm" />
              </Button>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
               <FormattedMessage id="server.add.reset" />
              </Button>
             </>
            )}
            <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
             <FormattedMessage id="server.add.back" />
            </Button>
           </div>
          </Box>
         </StepContent>
        ) : (
         <StepContent>
          <Stack spacing={2}>
           <Alert severity="info">
            <Typography>
             <FormattedMessage id="server.checkExecution" />
            </Typography>
           </Alert>
           {renderStepContent(index)}
           <CircularProgress />
          </Stack>
         </StepContent>
        )}
       </Step>
      ))}
     </Stepper>
    </Box>
    <Paper
     elevation={0}
     sx={{
      m: 5,
      p: 2,
      maxHeight: 560,
      position: "sticky",
      top: 30,
      minWidth: desktopViewPort ? 400 : "auto",
      borderRadius: "10px",
      boxShadow: 0
     }}>
     <Stack mb={2}>
      <Typography variant="h5" fontWeight="bold">
       <FormattedMessage id="server.add.recap" />
      </Typography>
     </Stack>
     <Divider textAlign="left">
      <Typography variant="overline">
       <FormattedMessage id="server.add.serverData" />
      </Typography>
     </Divider>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.name" />:
      </Typography>
      <Typography noWrap>{watch("name")}</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.provider" />:
      </Typography>
      <Typography>{watch("provider")}</Typography>
     </Stack>
     <Divider textAlign="left" sx={{ mt: 2 }}>
      <Typography variant="overline">
       <FormattedMessage id="server.add.serverSpecs" />
      </Typography>
     </Divider>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.os" />:
      </Typography>
      <Typography>{osList.find((element) => element.id === watch("os"))?.name || ""}</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.type" />:
      </Typography>
      <Typography>
       {typesList.find((element) => element.id === watch("serverType"))?.name || ""}
      </Typography>
     </Stack>
     <Stack direction="row" spacing={2} alignItems="center">
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.location" />:
      </Typography>
      <Typography noWrap>
       {locationList.find((element) => element.id === watch("location"))?.city || ""}
      </Typography>
      <Stack
       component="span"
       className={`fi fi-${
        locationList
         .find((element) => element.id === watch("location"))
         ?.country.toLocaleLowerCase() || ""
       }`}
      />
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.cores" />:
      </Typography>
      <Typography>
       {typesList.find((element) => element.id === watch("serverType"))?.cores || ""}
      </Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.ram" />:
      </Typography>
      <Typography>
       {`${typesList.find((element) => element.id === watch("serverType"))?.memory || "0"} GB`}
      </Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.disk" />:
      </Typography>
      <Typography>
       {`${typesList.find((element) => element.id === watch("serverType"))?.disk || "0"} GB`}
      </Typography>
     </Stack>
     <Divider textAlign="left" sx={{ mt: 2 }}>
      <Typography variant="overline">
       <FormattedMessage id="server.add.enabling" />
      </Typography>
     </Divider>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.enableIpv4" />:
      </Typography>
      <Typography>{watch("enableIpv4") ? "Attivo" : "Disattivo"}</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.enableIpv6" />:
      </Typography>
      <Typography>{watch("enableIpv6") ? "Attivo" : "Disattivo"}</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.enableBackup" />:
      </Typography>
      <Typography>{watch("enableBackup") ? "Attivo" : "Disattivo"}</Typography>
     </Stack>
     <Divider sx={{ my: 1 }} />
     <Stack>
      {watch("enableIpv4") && (
       <Stack direction="row" spacing={2}>
        <Typography minWidth={150} textAlign="start" fontWeight="bold">
         <FormattedMessage id="server.add.ipCost" />
        </Typography>
        <Typography fontSize={"small"} fontStyle="italic">
         <FormattedNumber
          value={
           Number(typesList.find((element) => element.id === watch("serverType"))?.ipv4_total) || 0
          }
          style={"currency"}
          currency="EUR"
         />
        </Typography>
       </Stack>
      )}
      {watch("enableBackup") && (
       <Stack direction="row" spacing={2}>
        <Typography minWidth={150} textAlign="start" fontWeight="bold">
         <FormattedMessage id="server.add.backupCost" />
        </Typography>
        <Typography fontSize={"small"} fontStyle="italic">
         <FormattedNumber
          value={
           Number(typesList.find((element) => element.id === watch("serverType"))?.backup_total) ||
           0
          }
          style={"currency"}
          currency="EUR"
         />
        </Typography>
       </Stack>
      )}
      {(watch("enableIpv4") || watch("enableBackup")) && (
       <Stack direction="row" spacing={2}>
        <Typography minWidth={150} textAlign="start" fontWeight="bold">
         <FormattedMessage id="server.add.serverCost" />
        </Typography>
        <Typography fontSize={"small"} fontStyle="italic">
         <FormattedNumber
          value={
           Number(
            typesList.find((element) => element.id === watch("serverType"))?.server_type_price
           ) || 0
          }
          style={"currency"}
          currency="EUR"
         />
        </Typography>
       </Stack>
      )}
      <Stack direction="row" spacing={2} alignItems="center">
       <Typography minWidth={150} textAlign="start" fontWeight="bold">
        <FormattedMessage id="server.add.totalPrice" />
       </Typography>
       <Typography fontSize={"x-large"} fontStyle="italic">
        <FormattedNumber
         value={
          formatServerPrice(
           typesList,
           watch("enableIpv4"),
           watch("enableBackup"),
           watch("serverType")
          ) || 0
         }
         style={"currency"}
         currency="EUR"
        />
       </Typography>
      </Stack>
     </Stack>
    </Paper>
   </Stack>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "server.newServer" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={
     userBalance <
      formatServerPrice(
       typesList,
       watch("enableIpv4"),
       watch("enableBackup"),
       watch("serverType")
      ) || loading
    }>
    <Stack>
     <FormattedMessage id="server.add.confirmNewServer" />
     <Stack direction="row" spacing={2}>
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.balance" />
      </Typography>
      <Typography>
       <FormattedNumber value={userBalance} style={"currency"} currency="EUR" />
      </Typography>
     </Stack>
     <Stack direction="row" spacing={2} alignItems="center">
      <Typography minWidth={150} textAlign="start" fontWeight="bold">
       <FormattedMessage id="server.add.totalPrice" />
      </Typography>
      <Typography>
       <FormattedNumber
        value={
         formatServerPrice(
          typesList,
          watch("enableIpv4"),
          watch("enableBackup"),
          watch("serverType")
         ) || 0
        }
        style={"currency"}
        currency="EUR"
       />
      </Typography>
     </Stack>
    </Stack>
   </AppModal>
  </Stack>
 ) : (
  <></>
 );
};

export default ServerWizard;
