import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getServerDetails } from "redux/selectors/serversSelector";

const ServiceLogs = ({ service }: { service: string }): ReactElement => {
 const intl = useIntl();
 const { socket } = useContext(AppContext);

 const server = useSelector(getServerDetails);

 const [logs, setLogs] = useState<string>("");
 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setIsLoading(true);
  setIsLoading(false);
  setOpenModal(false);
 };

 useEffect(() => {
  if (!isNil(socket)) {
   socket.on("server_logs", (value): void => {
    if (value?.length > 0) {
     for (let i = 0; i < value.length; i++) {
      if (server.agentoken === value[i]?.token) {
       switch (service) {
        case "apache2":
         setLogs(value[i]?.log_apache);
         break;
        case "postfix":
         setLogs(value[i]?.log_post);
         break;
        case "mysql":
         setLogs(value[i]?.log_mysql);
         break;
        case "ftp":
         setLogs(value[i]?.log_ftp);
         break;
        case "redis":
         setLogs(value[i]?.log_redi);
         break;
        default:
         setLogs("");
         break;
       }
      }
     }
    }
   });
  }
  return () => {
   !isNil(socket) && socket.off("server_logs");
  };
 }, [socket, server]);

 const Logs = () =>
  logs.split("<br>").map((log, index) => (
   <Stack key={`logs-list-${index}`}>
    <Typography>{log}</Typography>
    <Divider />
   </Stack>
  ));

 return (
  <>
   <Tooltip title={intl.formatMessage({ id: "app.logs" })} placement="top">
    <IconButton onClick={handleOpen}>
     <IconSelector icon="LogsIcon" props={{ fontSize: "small" }} />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleConfirm}
    showConfirmButton={false}
    title={intl.formatMessage({ id: "server.service.logs" })}>
    <Stack>
     {logs === "no" || logs === "" || !logs ? (
      <FormattedMessage id="server.services.logsNotProvided" />
     ) : (
      Logs()
     )}
    </Stack>
   </AppModal>
  </>
 );
};

export default ServiceLogs;
