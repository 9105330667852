import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

import ChangeVolumeName from "components/addons/volumes/actions/ChangeVolumeName";
import DeleteVolume from "components/addons/volumes/actions/DeleteVolume";
import ResizeVolume from "components/addons/volumes/actions/ResizeVolume";
import IconSelector from "components/shared/images/IconSelector";

import { IVolumesDataset } from "types/api/addonsApiInterface";

const VolumesMenu = ({ rowData }: { rowData: IVolumesDataset }) => {
 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <ChangeVolumeName id={rowData.id} oldName={rowData.name} closeMenu={handleClose} />
     <ResizeVolume id={rowData.id} size={rowData.size} closeMenu={handleClose} />
     {rowData.protection ? (
      <MenuItem disabled>
       <FormattedMessage id="volumes.deleteVolume" />
      </MenuItem>
     ) : (
      <DeleteVolume id={rowData.id} closeMenu={handleClose} />
     )}
    </Stack>
   </Menu>
  </>
 );
};

export default VolumesMenu;
