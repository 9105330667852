import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "redux/store";

import { IVolumesDataset } from "types/api/addonsApiInterface";

export const getVolumes = (state: AppState) => state.volumes;

export const getVolumesNumber = createSelector(getVolumes, (volumes) => {
 return volumes?.totalCount;
});

export const getVolumesDataset = createSelector(getVolumes, (volumes) => {
 return (
  volumes?.dataset?.map((volumes: IVolumesDataset) => {
   return {
    id: volumes.id,
    volume_id: volumes.volume_id,
    userid: volumes.userid,
    name: volumes.name,
    expire: volumes.expire,
    project_id: volumes.project_id,
    datacenter: volumes.datacenter,
    server: volumes.server,
    linux_device: volumes.linux_device,
    city: volumes.city,
    price: volumes.price,
    protection: volumes.protection,
    status: volumes.status,
    size: volumes.size,
    format: volumes.format,
    created: volumes.created,
    service_status: volumes.service_status,
    personal_name: volumes.personal_name
   };
  }) || []
 );
});
