import React, { ReactElement, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";

import Baremetal from "components/configurator/baremetal/Baremetal";
import Cloudbox from "components/configurator/cloudbox/Cloudbox";
import Gateway from "components/configurator/gateway/Gateway";
import Hetzner from "components/configurator/hetzner/Hetzner";

import { getIsAdmin } from "redux/selectors/userSelector";

import IspConfig from "../../components/configurator/isp/IspConfig";

const sections = [
 {
  name: "configurator.hetzner",
  component: <Hetzner />
 },
 {
  name: "configurator.isp",
  component: <IspConfig />
 },
 {
  name: "configurator.ssh",
  component: <div>ssh</div>
 },
 {
  name: "configurator.cloudbox",
  component: <Cloudbox />
 },
 {
  name: "configurator.baremetal",
  component: <Baremetal />
 },
 {
  name: "configurator.gateway",
  component: <Gateway />
 },
 {
  name: "configurator.partner",
  component: <div>ssh</div>
 },
 {
  name: "configurator.comands",
  component: <div>ssh</div>
 },
 {
  name: "configurator.agentTask",
  component: <div>ssh</div>
 },
 {
  name: "configurator.sync",
  component: <div>ssh</div>
 },
 {
  name: "configurator.voip",
  component: <div>ssh</div>
 }
];

const ConfiguratorPage = (): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));
 const navigate = useNavigate();

 const isAdmin = useSelector(getIsAdmin);

 const [value, setValue] = React.useState(0);

 useEffect(() => {
  if (!isAdmin) {
   navigate("/");
  }
 }, []);

 const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  setValue(newValue);
 };

 function TabPanel({
  value,
  index,
  children
 }: {
  value: number;
  index: number;
  children: React.ReactNode;
 }) {
  return (
   <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}>
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
   </div>
  );
 }

 return (
  <Container maxWidth="xl">
   <Paper sx={{ width: "100%", borderRadius: "10px", boxShadow: 0 }}>
    <Stack mt={3}>
     <h3>
      <FormattedMessage id="app.configurator" />
     </h3>
    </Stack>

    <Stack justifyContent="center">
     <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
       value={value}
       variant={desktopViewPort ? "standard" : "scrollable"}
       scrollButtons="auto"
       onChange={handleChange}
       aria-label="basic tabs example"
       centered
       sx={{ width: "100%" }}>
       {sections.map((section, index) => (
        <Tab
         key={`configurator-section-${index}`}
         label={intl.formatMessage({ id: section.name })}
        />
       ))}
      </Tabs>
     </Box>
     {sections.map((section, index) => (
      <TabPanel key={`configurator-section-${index}`} value={value} index={index}>
       {section.component}
      </TabPanel>
     ))}
    </Stack>
   </Paper>
  </Container>
 );
};

export default ConfiguratorPage;
