import React, { ReactElement } from "react";

import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import ZoneMenu from "components/zone/ZoneMenu";

import { getAllZone, updateZoneStatus } from "redux/handlers/zoneHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { zonesTableData } from "types/global/table";

const ZonesTable = ({
 rowData,
 index
}: {
 rowData: zonesTableData;
 index: number;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const handleSwitchToggle = async (ispId: number, zoneId: number) => {
  await dispatch(updateZoneStatus(ispId, zoneId));
  dispatch(getAllZone(0, 10));
 };

 return (
  <TableRow key={`zone-list-${index}`} sx={{ cursor: "pointer" }}>
   <TableCell component="th" scope="row">
    <Stack direction="row" alignItems="center" justifyContent="left" spacing={3}>
     <Typography variant="subtitle2" fontWeight="bold">
      {rowData.active === true ? (
       <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
      ) : (
       <IconSelector icon="StatusIcon" props={{ style: { color: "red" }, fontSize: "small" }} />
      )}
     </Typography>
    </Stack>
   </TableCell>
   <TableCell>
    <Typography variant="subtitle2">{rowData.zone}</Typography>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack direction="row" justifyContent="center" spacing={0.5}>
     <Typography variant="small">{rowData.dnsRr}</Typography>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack direction="row" justifyContent="center" spacing={0.5}>
     <Typography variant="small">{rowData.mail}</Typography>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack direction="row" justifyContent="center" spacing={0.5}>
     <Typography variant="small">{rowData.ttl}</Typography>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack direction="row" justifyContent="center" spacing={0}>
     <Switch
      checked={rowData.active}
      onClick={() => handleSwitchToggle(rowData.isp_id, rowData.zone_id)}
     />
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack direction="row" justifyContent="right" spacing={0}>
     <ZoneMenu
      zone_id={rowData.zone_id}
      data={rowData.records}
      isp_id={rowData.isp_id}
      name={rowData.zone}
     />
    </Stack>
   </TableCell>
  </TableRow>
 );
};

export default ZonesTable;
