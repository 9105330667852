import React, { ReactElement, useState } from "react";
import { useIntl } from "react-intl";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import CloudboxCommands from "./CloudboxCommands";
import CloudboxDetails from "./CloudboxDetails";
import CloudboxSettings from "./CloudboxSettings";

const CloudboxManage = (): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [section, setSection] = useState<number>(0);

 const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
  setSection(newValue);
 };

 const RenderTabContent = (): ReactElement => {
  switch (section) {
   case 0:
    return <CloudboxDetails />;
   case 1:
    return <CloudboxSettings />;
   case 2:
    return <CloudboxCommands />;
   default:
    return <CloudboxDetails />;
  }
 };

 return (
  <Stack>
   <Grid container spacing={2}>
    <Grid xs={12}>
     <Paper elevation={0} sx={{ mt: 3, pb: 0.7, borderRadius: "10px", boxShadow: 0 }}>
      <Tabs
       value={section}
       onChange={handleChangeTab}
       variant={desktopViewPort ? "standard" : "scrollable"}
       scrollButtons={true}
       allowScrollButtonsMobile>
       <Tab label={intl.formatMessage({ id: "addons.cloudbox.details" })} />
       <Tab label={intl.formatMessage({ id: "addons.cloudbox.settings" })} />
       <Tab label={intl.formatMessage({ id: "addons.cloudbox.commands" })} />
      </Tabs>
      <RenderTabContent />
     </Paper>
    </Grid>
   </Grid>
  </Stack>
 );
};

export default CloudboxManage;
