import React, { ReactElement, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import en from "locale/en.json";
import it from "locale/it.json";

import { isNil } from "ramda";

import Stack from "@mui/material/Stack";

import AddonsPage from "pages/addons/AddonsPage";
import CloudboxManagePage from "pages/addons/CloudboxManagePage";
import FirewallManagePage from "pages/addons/FirewallManagePage";
import LoadBalancerManagePage from "pages/addons/LoadBalancerManagePage";
import NetworkManagePage from "pages/addons/NetworkManagePage";
import ConfiguratorPage from "pages/admin/ConfiguratorPage";
import AppsPage from "pages/AppsPage";
import AddDomainsPage from "pages/domains/AddDomainsPage";
import DomainListPage from "pages/domains/DomainListPage";
import DomainManagePage from "pages/domains/DomainManagePage";
import EmailConfirmPage from "pages/EmailConfirmPage";
import LoginPage from "pages/LoginPage";
import NewPasswordPage from "pages/NewPasswordPage";
import UserProfilePage from "pages/profilePages/UserProfilePage";
import RegistrationPage from "pages/RegistrationPage";
import ResetPasswordPage from "pages/ResetPasswordPage";
import ServerManagePage from "pages/server/ServerManagePage";
import ServersPage from "pages/server/ServersPage";

import AppContainer from "components/layout/AppContainer";
import MailsManage from "components/mails/MailsManage";
import PageLoading from "components/shared/loaders/PageLoading";
import Alerts from "components/shared/notification/Alerts";
import PaymentConfirm from "components/shared/stripe/PaymentConfirm";
import PaymentPage from "components/shared/stripe/PaymentPage";
import Test from "components/shared/Test";
import WebsitesManagePage from "components/websites/websitesDetails/WebsitesManagePages";
import BaremetalWizard from "components/wizard/BaremetalWizard";
import CloudboxWizard from "components/wizard/CloudboxWizard";
import FirewallWizard from "components/wizard/FirewallWizard";
import FloatingIpWizard from "components/wizard/FloatingIpWizard";
import LoadBalancerWizard from "components/wizard/LoadBalancerWizard";
import MailsWizard from "components/wizard/MailsWizard";
import NetworkWizard from "components/wizard/NetworkWizard";
import ServerFromBackupWizard from "components/wizard/ServerFromBackupWizard";
import ServerFromSnapshotWizard from "components/wizard/ServerFromSnapshotWizard";
import ServerWizard from "components/wizard/ServerWizard";
import SitesWizard from "components/wizard/SitesWizard";
import VolumesWizard from "components/wizard/VolumesWizard";

import { getMe } from "redux/handlers/userHandler";

import { getUserAuthenticated, getUserLanguage } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { Routes } from "types/global/routes";

import "dayjs/locale/en-gb";
import "./App.css";

import { AppContext, useApp } from "./AppContext";

function App(): ReactElement {
 const dispatch = useAppDispatch();

 const isAllowed: boolean = useSelector(getUserAuthenticated);

 const userLanguage: string = useSelector(getUserLanguage);

 const [isLoading, setIsLoading] = useState<boolean>(true);

 const JWT = localStorage.getItem("jwt");

 const handleLoadingPage = async (): Promise<void> => {
  await dispatch(getMe());
  setIsLoading(false);
 };

 const handleTranslations = () => {
  switch (userLanguage) {
   case "en":
    return en;
   default:
    return it;
  }
 };

 const ProtectedRoute = ({ component, redirectPath }: Routes): ReactElement => {
  const defaultRedirectPath: string = redirectPath || "/login";

  if (!isAllowed) {
   return <Navigate to={defaultRedirectPath} replace />;
  }

  return <AppContainer>{component}</AppContainer> || <div>Component Not Found</div>;
 };

 const router = createBrowserRouter([
  {
   path: "/",
   element: <ProtectedRoute component={<Test />} />
  },
  {
   path: "/admin/configurator",
   element: <ProtectedRoute component={<ConfiguratorPage />} />
  },
  {
   path: "/domains",
   element: <ProtectedRoute component={<DomainListPage />} />
  },
  {
   path: "/domains/manage/:id/:section",
   element: <ProtectedRoute component={<DomainManagePage />} />
  },
  {
   path: "/domains/add",
   element: <ProtectedRoute component={<AddDomainsPage />} />
  },
  {
   path: "/servers",
   element: <ProtectedRoute component={<ServersPage />} />
  },
  {
   path: "/servers/add",
   element: <ProtectedRoute component={<ServerWizard />} />
  },
  {
   path: "/servers/add/baremetal",
   element: <ProtectedRoute component={<BaremetalWizard />} />
  },
  {
   path: "/servers/add/frombackup/:id",
   element: <ProtectedRoute component={<ServerFromBackupWizard />} />
  },
  {
   path: "/servers/add/fromsnapshot/:id",
   element: <ProtectedRoute component={<ServerFromSnapshotWizard />} />
  },
  {
   path: "/servers/manage/:id/:section",
   element: <ProtectedRoute component={<ServerManagePage />} />
  },
  {
   path: "/app/:section",
   element: <ProtectedRoute component={<AppsPage />} />
  },
  {
   path: "/app/sites/add",
   element: <ProtectedRoute component={<SitesWizard />} />
  },
  {
   path: "/app/sites/:domain_id/:section",
   element: <ProtectedRoute component={<WebsitesManagePage />} />
  },
  {
   path: "/app/mail/:mail_id/:section",
   element: <ProtectedRoute component={<MailsManage />} />
  },
  {
   path: "/app/mail/add",
   element: <ProtectedRoute component={<MailsWizard />} />
  },
  {
   path: "/addons/:section",
   element: <ProtectedRoute component={<AddonsPage />} />
  },
  {
   path: "/addons/cloudbox/manage/:id/:section",
   element: <ProtectedRoute component={<CloudboxManagePage />} />
  },
  {
   path: "/addons/network/manage/:id/:section",
   element: <ProtectedRoute component={<NetworkManagePage />} />
  },
  {
   path: "/addons/firewall/manage/:id/:section",
   element: <ProtectedRoute component={<FirewallManagePage />} />
  },
  {
   path: "/addons/loadBalancer/manage/:id/:section",
   element: <ProtectedRoute component={<LoadBalancerManagePage />} />
  },
  {
   path: "/addons/cloudbox/add",
   element: <ProtectedRoute component={<CloudboxWizard />} />
  },
  {
   path: "/addons/firewall/add",
   element: <ProtectedRoute component={<FirewallWizard />} />
  },
  {
   path: "/addons/network/add",
   element: <ProtectedRoute component={<NetworkWizard />} />
  },
  {
   path: "/addons/volumes/add",
   element: <ProtectedRoute component={<VolumesWizard />} />
  },
  {
   path: "/addons/floatingip/add",
   element: <ProtectedRoute component={<FloatingIpWizard />} />
  },
  {
   path: "/addons/loadBalancer/add",
   element: <ProtectedRoute component={<LoadBalancerWizard />} />
  },
  {
   path: "/profile/:section",
   element: <ProtectedRoute component={<UserProfilePage />} />
  },
  {
   path: "/profile/recharge/payment",
   element: <ProtectedRoute component={<PaymentPage />} />
  },
  {
   path: "/profile/recharge/payment/success",
   element: <ProtectedRoute component={<PaymentConfirm />} />
  },
  {
   path: "/login",
   element: <LoginPage />
  },
  {
   path: "/reset-password",
   element: <ResetPasswordPage />
  },
  {
   path: "/reset-password/:hash",
   element: <NewPasswordPage />
  },
  {
   path: "/registration",
   element: <RegistrationPage />
  },
  {
   path: "/registration/emailchecked",
   element: <EmailConfirmPage />
  }
 ]);

 useEffect(() => {
  if (!isNil(JWT)) {
   handleLoadingPage();
  } else {
   setIsLoading(false);
  }
 }, []);

 return (
  <IntlProvider defaultLocale={userLanguage} locale={userLanguage} messages={handleTranslations()}>
   <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
    <AppContext.Provider value={useApp(isAllowed)}>
     <Alerts />
     <Stack className="App">
      {isLoading ? <PageLoading /> : <RouterProvider router={router} />}
     </Stack>
    </AppContext.Provider>
   </LocalizationProvider>
  </IntlProvider>
 );
}
export default App;
