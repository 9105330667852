import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { snapshotTableData } from "types/global/table";

import ChangeName from "./actions/ChangeName";
import DeleteSnapshot from "./actions/DeleteSnapshot";

const SnapshotsActions = ({
 rowData,
 server
}: {
 rowData: snapshotTableData;
 server: string;
}): ReactElement => {
 const navigate = useNavigate();

 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     <ChangeName id={rowData.id} oldName={rowData.description} closeMenu={handleClose} />
     <MenuItem
      onClick={() =>
       navigate(`/servers/add/fromsnapshot/${rowData.id}`, {
        state: { server: server === "" ? true : false }
       })
      }>
      <FormattedMessage id="addons.snapshot.createServer" />
     </MenuItem>
     <DeleteSnapshot id={rowData.id} disabled={rowData.protection} closeMenu={handleClose} />
    </Stack>
   </Menu>
  </>
 );
};

export default SnapshotsActions;
