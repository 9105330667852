import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { sxStyle } from "theme/sections/server";

import { serverAddonsLinks } from "constants/staticLinks";

import IconSelector from "components/shared/images/IconSelector";

import { getAllServerAddons } from "redux/handlers/serverHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IServerAddonsApi } from "types/api/serversApiInterface";

const ServerManageAddons = ({ id }: { id: number }): ReactElement => {
 const navigate = useNavigate();
 const location = useLocation();
 const dispatch = useAppDispatch();

 const [addonsData, setAddonsData] = useState<IServerAddonsApi | null>(null);

 useEffect(() => {
  (async () => {
   setAddonsData(await dispatch(getAllServerAddons(id)));
  })();
 }, []);

 const handleNavigate = (url: string) => {
  navigate(`/servers/manage/${id}/${url}`);
 };

 const checkAddonData = (type: string): boolean => {
  if (addonsData) {
   switch (type) {
    case "volumes":
     return addonsData.volume.length > 0;
    case "snapshot":
     return addonsData.snapshot.length > 0;
    case "floatingip":
     return addonsData.floating_ip.length > 0;
    case "network":
     return addonsData.networks.length > 0;
    default:
     return false;
   }
  } else {
   return false;
  }
 };

 const getAddonValue = (type: string): number => {
  if (addonsData) {
   switch (type) {
    case "volumes":
     return addonsData.volume.length;
    case "snapshot":
     return addonsData.snapshot.length;
    case "floatingip":
     return addonsData.floating_ip.length;
    case "network":
     return addonsData.networks.length;
    default:
     return 0;
   }
  } else {
   return 0;
  }
 };

 return (
  <Stack py={serverAddonsLinks.filter((element) => checkAddonData(element.url)).length > 0 ? 2 : 0}>
   {serverAddonsLinks.filter((element) => checkAddonData(element.url)).length > 0 && (
    <Stack alignItems="flex-start" mb={1}>
     <Typography variant="caption" fontWeight="bold">
      ADDONS
     </Typography>
    </Stack>
   )}
   {serverAddonsLinks
    .filter((element) => checkAddonData(element.url))
    .map((element, index) => (
     <Stack
      key={`server-addons-menu-${index}`}
      direction="row"
      justifyContent="space-between"
      sx={sxStyle}
      onClick={() => handleNavigate(element.url)}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
       <Stack direction="row" alignItems="center">
        <Stack
         sx={{
          backgroundColor: location.pathname.slice(1).includes(element?.url.slice(1))
           ? "#EF711A"
           : "lightgray",
          justifyContent: "center",
          borderRadius: "50%",
          height: "35px",
          minWidth: "35px",
          alignItems: "center"
         }}>
         <IconSelector
          icon={element.icon}
          props={{
           fontSize: "small",
           color: location.pathname.slice(1).includes(element?.url.slice(1))
            ? "tertiary"
            : "disabled"
          }}
         />
        </Stack>
        <Typography sx={{ ml: 4 }}>
         <FormattedMessage id={element.name} />
        </Typography>
       </Stack>
       <Chip color="primary" size="small" sx={{ mr: 3 }} label={getAddonValue(element.url)} />
      </Stack>
     </Stack>
    ))}
  </Stack>
 );
};

export default ServerManageAddons;
