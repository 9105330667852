/* eslint-disable react/style-prop-object */
import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { isNil } from "ramda";

import ReportProblemIcon from "@mui/icons-material/ReportProblem";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import {
 postChangeRescale,
 postServerPossibleUpgrades,
 postUpgradeServer
} from "redux/handlers/serverHandler";

import { getServerDetails } from "redux/selectors/serversSelector";
import { getUserBalance } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { UpgradeTypesAPI } from "types/api/serversApiInterface";

const ServerUpgrade = (): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));
 const dispatch = useAppDispatch();
 const navigate = useNavigate();

 const server = useSelector(getServerDetails);
 const userBalance = useSelector(getUserBalance);

 const [tabValue, setTabValue] = useState<number>(0);
 const [selectedUpgrade, setSelectedUpgrade] = useState<UpgradeTypesAPI>();
 const [possibleUpgrades, setPossibleUpgrades] = useState<Array<UpgradeTypesAPI>>([]);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [temporaryRescale, setTemporaryRescale] = useState<boolean>(true);
 const [loading, setLoading] = useState<boolean>(false);

 const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  setTabValue(newValue);
 };

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleChangeRescale = async () => {
  setTemporaryRescale(!temporaryRescale);
  setSelectedUpgrade(undefined);
  await dispatch(postChangeRescale(server.id || 0));
  handleGetUpgrades();
 };

 const handleGetUpgrades = async () => {
  if (server.id) setPossibleUpgrades(await dispatch(postServerPossibleUpgrades(server.id || 0)));
 };

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postUpgradeServer(
    server?.id || 0,
    selectedUpgrade?.id || 0,
    (selectedUpgrade?.price || 0) - (server.price || 0)
   )
  );
  setLoading(false);
  handleClose();
  navigate(`/servers/manage/${server?.id}/specification`);
 };

 useEffect(() => {
  handleGetUpgrades();
 }, [temporaryRescale]);

 useEffect(() => {
  setTemporaryRescale(server.rescale || false);
  handleGetUpgrades();
 }, [server]);

 return (
  <Paper elevation={0} sx={{ mt: 2, borderRadius: "10px", boxShadow: 0 }}>
   <Stack direction="column" p={2}>
    <Stack direction="row" alignItems="center">
     <IconSelector icon="UpgradeIcon" props={{ fontSize: "medium", color: "secondary" }} />
     <Typography fontWeight="bold" fontSize={16} ml={1} textTransform="uppercase">
      <FormattedMessage id="server.upgrade" />
     </Typography>
    </Stack>
    <Stack direction={"column"} spacing={2} mb={2}>
     <Typography paragraph align="left" variant="subtitle2">
      <FormattedMessage id="server.upgrade.introduction" values={{ br: <br /> }} />
     </Typography>
     <Stack
      sx={{
       alignItems: "flex-start",
       px: 2,
       py: 1,
       borderRadius: 2,
       border: "2px solid #BDE3FB",
       backgroundColor: "#DAF0FF"
      }}>
      <FormControlLabel
       control={<Checkbox checked={temporaryRescale} onChange={handleChangeRescale} />}
       label={intl.formatMessage({ id: "server.upgrade.rescale" })}
      />
      <Typography>
       <FormattedMessage id="server.upgrade.rescaleMessage" />
      </Typography>
     </Stack>
    </Stack>
    {possibleUpgrades.length > 0 ? (
     <Grid container spacing={4}>
      <Grid xs={desktopViewPort ? 8 : 12}>
       <Stack>
        <Box sx={{ width: "100%", typography: "body1" }}>
         <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
           <Tab label="Standard" />
           <Tab label="Dedicated" />
          </Tabs>
         </Box>
         <TableContainer>
          <Table>
           <TableHead>
            <TableRow>
             <TableCell></TableCell>
             <TableCell>
              <FormattedMessage id="server.upgrade.machineName" />
             </TableCell>
             <TableCell>
              <FormattedMessage id="server.upgrade.machineCores" />
             </TableCell>
             <TableCell>
              <FormattedMessage id="server.upgrade.machineRam" />
             </TableCell>
             <TableCell>
              <FormattedMessage id="server.upgrade.machineDisk" />
             </TableCell>
             <TableCell>
              <FormattedMessage id="server.upgrade.machinePrice" />
             </TableCell>
            </TableRow>
           </TableHead>
           {tabValue === 0 ? (
            <TableBody
             role="tabpanel"
             id={`simple-tabpanel-${0}`}
             aria-labelledby={`simple-tab-${0}`}>
             {possibleUpgrades
              .filter((element) => !element.name.includes("VFC"))
              .map((element, index) => {
               return (
                <TableRow
                 key={`upgrade-table-${index}`}
                 onClick={() => {
                  if (userBalance >= element.price - (server.price || 0))
                   setSelectedUpgrade(element);
                 }}
                 sx={{ cursor: "pointer" }}>
                 <TableCell>
                  <Radio checked={element.id === selectedUpgrade?.id} />
                 </TableCell>
                 <TableCell>
                  <Typography>{element.name}</Typography>
                 </TableCell>
                 <TableCell>
                  <Typography>{element.cores}</Typography>
                 </TableCell>
                 <TableCell>
                  <Stack direction={"row"}>
                   <Typography>{element.memory}</Typography>
                   <Typography
                    variant="caption"
                    alignItems="flex-end"
                    display="flex"
                    textTransform="uppercase">
                    GB
                   </Typography>
                  </Stack>
                 </TableCell>
                 <TableCell>
                  <Stack direction={"row"}>
                   <Typography>{element.disk}</Typography>
                   <Typography
                    variant="caption"
                    alignItems="flex-end"
                    display="flex"
                    textTransform="uppercase">
                    GB
                   </Typography>
                  </Stack>
                 </TableCell>
                 <TableCell>
                  <Stack direction="row" spacing={1} alignContent="center">
                   <Typography
                    fontStyle="italic"
                    fontWeight="bold"
                    color={userBalance < element.price - (server.price || 0) ? "red" : "green"}>
                    <FormattedNumber value={element.price} style="currency" currency="EUR" />
                   </Typography>
                   {userBalance < element.price - (server.price || 0) && (
                    <Tooltip
                     title={intl.formatMessage({
                      id: "server.upgrade.balanceInsufficient"
                     })}>
                     <ReportProblemIcon color="warning" />
                    </Tooltip>
                   )}
                  </Stack>
                 </TableCell>
                </TableRow>
               );
              })}
            </TableBody>
           ) : (
            <TableBody
             role="tabpanel"
             hidden={tabValue !== 1}
             id={`simple-tabpanel-${1}`}
             aria-labelledby={`simple-tab-${1}`}>
             {possibleUpgrades
              .filter((element) => element.name.includes("VFC"))
              .map((element, index) => {
               return (
                <TableRow
                 key={`upgrade-table-${index}`}
                 onClick={() => {
                  if (userBalance >= element.price) setSelectedUpgrade(element);
                 }}
                 sx={{ cursor: "pointer" }}>
                 <TableCell>
                  <Radio checked={element.id === selectedUpgrade?.id} />
                 </TableCell>
                 <TableCell>
                  <Typography>{element.name}</Typography>
                 </TableCell>
                 <TableCell>
                  <Typography>{element.cores}</Typography>
                 </TableCell>
                 <TableCell>
                  <Stack direction={"row"}>
                   <Typography>{element.memory}</Typography>
                   <Typography
                    variant="caption"
                    alignItems="flex-end"
                    display="flex"
                    textTransform="uppercase">
                    GB
                   </Typography>
                  </Stack>
                 </TableCell>
                 <TableCell>
                  <Stack direction={"row"}>
                   <Typography>{element.disk}</Typography>
                   <Typography
                    variant="caption"
                    alignItems="flex-end"
                    display="flex"
                    textTransform="uppercase">
                    GB
                   </Typography>
                  </Stack>
                 </TableCell>
                 <TableCell>
                  <Stack direction="row" spacing={1} alignContent="center">
                   <Typography
                    fontStyle="italic"
                    fontWeight="bold"
                    color={userBalance < element.price ? "red" : "green"}>
                    <FormattedNumber value={element.price} style="currency" currency="EUR" />
                   </Typography>
                   {userBalance < element.price && (
                    <Tooltip
                     title={intl.formatMessage({
                      id: "server.upgrade.balanceInsufficient"
                     })}>
                     <ReportProblemIcon color="warning" />
                    </Tooltip>
                   )}
                  </Stack>
                 </TableCell>
                </TableRow>
               );
              })}
            </TableBody>
           )}
          </Table>
         </TableContainer>
        </Box>
       </Stack>
      </Grid>
      <Grid xs={desktopViewPort ? 4 : 12}>
       <Stack spacing={2}>
        <Stack>
         <Typography fontWeight="bold" fontSize={16} ml={1} textTransform="uppercase">
          <FormattedMessage id="server.upgrade.machineDetails" />
         </Typography>
         <Stack direction={"row"}>
          <Typography fontWeight="bold">
           <FormattedMessage id="server.upgrade.machineCores" />:
          </Typography>
          <Typography ml={1}>{server.cpuCore}</Typography>
         </Stack>
         <Stack direction={"row"}>
          <Typography fontWeight="bold">
           <FormattedMessage id="server.upgrade.machineRam" />:
          </Typography>
          <Typography ml={1}>{server.ramSize}</Typography>
          <Typography
           variant="caption"
           alignItems="flex-end"
           display="flex"
           textTransform="uppercase">
           GB
          </Typography>
         </Stack>
         <Stack direction={"row"}>
          <Typography fontWeight="bold">
           <FormattedMessage id="server.upgrade.machineDisk" />:
          </Typography>
          <Typography ml={1}>{server.diskSize}</Typography>
          <Typography
           variant="caption"
           alignItems="flex-end"
           display="flex"
           textTransform="uppercase">
           GB
          </Typography>
         </Stack>
         <Stack direction={"row"}>
          <Typography fontWeight="bold">
           <FormattedMessage id="server.upgrade.machineType" />:
          </Typography>
          <Typography textTransform="uppercase" ml={1}>
           {server.server_type}
          </Typography>
         </Stack>
         <Stack direction={"row"}>
          <Typography fontWeight="bold">
           <FormattedMessage id="server.upgrade.machinePrice" />:
          </Typography>
          <Typography ml={1}>
           <FormattedNumber
            value={isNil(server.price) ? 0 : server.price}
            style="currency"
            currency="EUR"
           />
          </Typography>
         </Stack>
        </Stack>
        {!isNil(selectedUpgrade) && (
         <Stack>
          <Typography fontWeight="bold" fontSize={16} ml={1} textTransform="uppercase">
           <FormattedMessage id="server.upgrade.upgradeDetails" />
          </Typography>
          <Stack direction={"row"}>
           <Typography fontWeight="bold">
            <FormattedMessage id="server.upgrade.machineCores" />:
           </Typography>
           <Typography ml={1}>{selectedUpgrade?.cores}</Typography>
          </Stack>
          <Stack direction={"row"}>
           <Typography fontWeight="bold">
            <FormattedMessage id="server.upgrade.machineRam" />:
           </Typography>
           <Typography ml={1}>{selectedUpgrade?.memory}</Typography>
           <Typography
            variant="caption"
            alignItems="flex-end"
            display="flex"
            textTransform="uppercase">
            GB
           </Typography>
          </Stack>
          <Stack direction={"row"}>
           <Typography fontWeight="bold">
            <FormattedMessage id="server.upgrade.machineDisk" />:
           </Typography>
           <Typography ml={1}>
            {temporaryRescale ? server.diskSize : selectedUpgrade?.disk}
           </Typography>
           <Typography
            variant="caption"
            alignItems="flex-end"
            display="flex"
            textTransform="uppercase">
            GB
           </Typography>
          </Stack>
          <Stack direction={"row"}>
           <Typography fontWeight="bold">
            <FormattedMessage id="server.upgrade.machineType" />:
           </Typography>
           <Typography ml={1}>{selectedUpgrade?.name}</Typography>
          </Stack>
          <Stack direction={"row"}>
           <Typography fontWeight="bold">
            <FormattedMessage id="server.upgrade.machinePrice" />:
           </Typography>
           <Typography ml={1}>
            <FormattedNumber value={selectedUpgrade?.price} style="currency" currency="EUR" />
           </Typography>
          </Stack>
         </Stack>
        )}
        {!isNil(selectedUpgrade) && (
         <Button
          variant="kxActionButton"
          onClick={handleOpen}
          disabled={server.server_status !== "off"}>
          <FormattedMessage id="server.upgrade.confirmUpgrade" />
         </Button>
        )}
        {!isNil(selectedUpgrade) && server.server_status !== "off" && (
         <Stack>
          <Typography>
           <FormattedMessage id="server.upgrade.turnOffServer" />
          </Typography>
         </Stack>
        )}
        {!isNil(selectedUpgrade) && (
         <AppModal
          open={openModal}
          close={handleClose}
          title={intl.formatMessage({ id: "server.upgrade.upgradeModal" })}
          handleClose={handleClose}
          disabled={
           userBalance < selectedUpgrade.price - (server.price || 0) ||
           server.server_status !== "off" ||
           loading
          }
          handleConfirm={handleConfirm}>
          <Stack>
           <Typography>
            <FormattedMessage id="server.upgrade.confirmMessage" />
            <FormattedNumber value={selectedUpgrade?.price} style="currency" currency="EUR" />
            <FormattedMessage id="server.upgrade.confirmMessage2" />
           </Typography>
           <Stack direction="row" spacing={2}>
            <Typography fontWeight="bold">
             <FormattedMessage id="server.upgrade.operationPrice" />
            </Typography>
            <Typography fontStyle="italic" fontWeight="bold" color={"green"}>
             <FormattedNumber
              value={selectedUpgrade?.price - (server.price || 0)}
              style="currency"
              currency="EUR"
             />
            </Typography>
           </Stack>
           <Typography variant="caption" fontStyle="italic">
            <FormattedMessage id="server.upgrade.operationPriceMessage" />
           </Typography>
          </Stack>
         </AppModal>
        )}
       </Stack>
      </Grid>
     </Grid>
    ) : (
     <Stack>
      <Skeleton variant="rectangular" width={"100%"} height={60} animation="wave" />
     </Stack>
    )}
   </Stack>
  </Paper>
 );
};

export default ServerUpgrade;
