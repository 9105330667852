import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getAllContainers, getPorts, postRedirectProxy } from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IContainerData, IDockerPorts } from "types/api/dockerApiInterface";

const RedirectProxy = ({ id }: { id: number }): ReactElement => {
 const dispatch = useAppDispatch();
 const intl = useIntl();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [ports, setPorts] = useState<Array<IDockerPorts>>([]);
 const [containers, setContainers] = useState<Array<IContainerData>>([]);

 const handleOpen = async () => {
  reset();
  setPorts(await dispatch(getPorts()));
  const containersList = await dispatch(getAllContainers(id));
  setContainers(containersList.filter((element) => element.type !== "proxy-site"));
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const { control, reset, watch, setValue, handleSubmit } = useForm({
  defaultValues: {
   name: "",
   port: 0,
   domain: ""
  }
 });

 const onSubmit: SubmitHandler<{
  name: string;
  port: number;
  domain: string;
 }> = async (data: { name: string; port: number; domain: string }) => {
  setLoading(true);
  await dispatch(postRedirectProxy(id, data.name, data.port, data.domain));
  handleClose();
  setLoading(false);
 };

 const checkDisabled = (): boolean => {
  return watch("name") === "" || watch("port") === 0 || watch("domain") === "";
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="docker.proxy.redirectProxyToContainer" />}>
    <IconButton onClick={() => handleOpen()}>
     <IconSelector icon="MoveUpIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.proxy.redirectProxyToContainer" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={loading || checkDisabled()}>
    {containers.length === 0 ? (
     <Alert severity="error">
      <FormattedMessage id="docker.proxy.cannotRedirectProxy" />
     </Alert>
    ) : (
     <Stack spacing={2}>
      <Controller
       name="domain"
       control={control}
       rules={{ required: true }}
       render={({ field }) => (
        <TextField
         {...field}
         autoComplete="off"
         label={intl.formatMessage({ id: "docker.proxy.domain" })}
         InputLabelProps={{ shrink: true }}
         onChange={({ currentTarget }) => setValue("domain", currentTarget.value)}
        />
       )}
      />
      <Controller
       name="port"
       control={control}
       rules={{ required: true }}
       render={({ field }) => (
        <FormControl {...field} fullWidth>
         <InputLabel>
          <FormattedMessage id="docker.proxy.ports" />
         </InputLabel>
         <Select
          value={watch("port")}
          label={<FormattedMessage id="docker.proxy.ports" />}
          onChange={(e) => setValue("port", Number(e.target.value))}>
          {ports.map((element, index) => {
           return (
            <MenuItem value={element.number} key={`port-vers-${index}`}>
             {element.number}
            </MenuItem>
           );
          })}
         </Select>
        </FormControl>
       )}
      />
      <Controller
       name="name"
       control={control}
       rules={{ required: true }}
       render={({ field }) => (
        <FormControl {...field} fullWidth>
         <InputLabel>
          <FormattedMessage id="docker.proxy.container" />
         </InputLabel>
         <Select
          value={watch("name")}
          label={<FormattedMessage id="docker.proxy.container" />}
          onChange={(e) => setValue("name", e.target.value)}>
          {containers.map((element, index) => {
           return (
            <MenuItem value={element.name} key={`containers-${index}`}>
             {element.name}
            </MenuItem>
           );
          })}
         </Select>
        </FormControl>
       )}
      />
     </Stack>
    )}
   </AppModal>
  </>
 );
};

export default RedirectProxy;
