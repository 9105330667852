import React, { ReactElement, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { isNil } from "ramda";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { deleteRecord } from "redux/handlers/zoneHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { Record } from "types/api/appsApiInterface";

import AddRecord from "./recordsActions/AddRecord";
import EditRecord from "./recordsActions/EditRecord";

const TableHeadList: { align: "left" | "center" | "right"; name: string; width?: string }[] = [
 {
  align: "left",
  name: "zone.type"
 },
 {
  align: "center",
  name: "zone.name",
  width: "30%"
 },
 {
  align: "center",
  name: "zone.data",
  width: "15%"
 },
 {
  align: "center",
  name: "zone.aux"
 },
 {
  align: "center",
  name: "zone.ttl"
 },
 {
  align: "center",
  name: "zone.actions",
  width: "10%"
 }
];

type Props = {
 zoneId: number;
 ispId: number;
 data: Record[];
 domain: string;
 reloadData?: () => void;
};

const ZoneRecords = ({ zoneId, ispId, data, domain, reloadData }: Props): ReactElement => {
 const dispatch = useAppDispatch();
 const searchRef = useRef<HTMLInputElement>(null);

 const defaultRecord: Record = {
  aux: 0,
  data: "",
  id: -1,
  isp_id: ispId,
  name: "test",
  status: "active",
  ttl: 3600,
  type: "A",
  zone_id: zoneId
 };

 const [loading, setLoading] = useState<boolean>(false);
 const [currentList, setCurrentList] = useState<Record[]>(data.slice(0, 10));
 const [editIndex, setEditIndex] = useState<number>(-1);
 const [deleteIndex, setDeleteIndex] = useState<number>(-1);
 const [currentPage, setCurrentPage] = useState<number>(0);

 const handleSearchZone = (zone: string): void => {
  setEditIndex(-1);
  setDeleteIndex(-1);
  if (zone === "") {
   setCurrentList(data.slice(0, 10));
  } else {
   setCurrentList(
    data.filter((row) => !isNil(row.name) && row.name.toLowerCase().includes(zone)).slice(0, 5)
   );
  }
 };

 const handleOnPageChange = (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  setCurrentPage(newPage);
  setCurrentList(data.filter((row, index) => index >= 10 * newPage && index < 10 * newPage + 10));
 };

 const AddNewRecord = (): void => {
  setCurrentList([defaultRecord, ...currentList]);
  setEditIndex(0);
 };

 const handleSaveRecord = () => {
  setEditIndex(-1);
  setCurrentList(data.filter((row, index) => index < 10));
  reloadData && reloadData();
 };

 const handleEditRecord = () => {
  setEditIndex(-1);
  reloadData && reloadData();
 };

 const handleDeleteRecord = async (index: number) => {
  setLoading(true);
  const currentRecord = currentList[index];
  await dispatch(deleteRecord(currentRecord.id, currentRecord.isp_id, currentRecord.type));
  setEditIndex(-1);
  setDeleteIndex(-1);
  setLoading(false);
  reloadData && reloadData();
 };

 const RenderEmptyState = (): ReactElement => (
  <Stack sx={{ mt: 2 }}>
   <Alert severity="warning" variant="outlined">
    <FormattedMessage id="record.notFound" />
   </Alert>
  </Stack>
 );

 const RenderRow = ({ row }: { row: Record }): ReactElement => {
  return (
   <>
    <TableCell component="th" scope="row" align="left">
     {row.type}
    </TableCell>
    <TableCell component="th" scope="row" align="center">
     {row.name}
    </TableCell>
    <TableCell component="th" scope="row" align="center">
     <Typography variant="subtitle2" sx={{ "overflow-wrap": "break-word", width: 300 }}>
      {row.data}
     </Typography>
    </TableCell>
    <TableCell component="th" scope="row" align="center">
     {row.aux}
    </TableCell>
    <TableCell component="th" scope="row" align="center">
     {row.ttl}
    </TableCell>
   </>
  );
 };

 const handleResetAddIndex = () => {
  setEditIndex(-1);
  setCurrentList(data.filter((row, index) => index < 10));
 };

 const RenderConfirmDelete = ({ index }: { index: number }): ReactElement => {
  return !loading ? (
   <Stack justifyContent="center">
    <Typography variant="subtitle2" color="error">
     <FormattedMessage id="domains.record.delete" />
    </Typography>
    <Stack direction="row" justifyContent="center">
     <IconButton
      onClick={() => {
       handleDeleteRecord(index);
      }}>
      <IconSelector icon="StatusIcon" props={{ style: { color: "green" }, fontSize: "small" }} />
     </IconButton>
     <IconButton
      onClick={() => {
       setDeleteIndex(-1);
      }}>
      <CancelIcon color="action" />
     </IconButton>
    </Stack>
   </Stack>
  ) : (
   <CircularProgress />
  );
 };

 const RenderRecordList = (): ReactElement => {
  return (
   <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
    <Table sx={{ minWidth: 800 }} aria-label="record table">
     <TableHead>
      <TableRow>
       {TableHeadList.map((item, index) => (
        <TableCell key={index} align={item.align} width={item?.width || "auto"}>
         <FormattedMessage id={item.name} />
        </TableCell>
       ))}
      </TableRow>
     </TableHead>
     <TableBody>
      {currentList.map((row, index) =>
       row.id === -1 ? (
        <AddRecord
         key={`add-index-${index}`}
         zoneId={zoneId}
         ispId={ispId}
         domain={domain}
         resetIndex={handleResetAddIndex}
         updateData={handleSaveRecord}
        />
       ) : editIndex === index ? (
        <EditRecord
         key={`add-index-${index}`}
         zoneId={zoneId}
         ispId={ispId}
         domain={domain}
         rowData={row}
         resetIndex={() => setEditIndex(-1)}
         updateData={handleEditRecord}
        />
       ) : (
        <TableRow key={`record-list-${index}`} id={`record-element-${index}`}>
         <RenderRow row={row} />
         <TableCell component="th" scope="row" align="center" width="10%">
          {deleteIndex === index ? (
           <RenderConfirmDelete index={index} />
          ) : row.status === "active" ? (
           <Stack direction="row" justifyContent="center">
            <IconButton
             onClick={() => {
              setEditIndex(index);
              setDeleteIndex(-1);
             }}>
             <Tooltip title="Edit" placement="top">
              <IconSelector icon="EditIcon" props={{ color: "action" }} />
             </Tooltip>
            </IconButton>
            <IconButton
             onClick={() => {
              setEditIndex(-1);
              setDeleteIndex(index);
             }}>
             <Tooltip title={`Delete Record ${row.name}`} placement="top">
              <IconSelector icon="DeleteIcon" props={{ color: "error" }} />
             </Tooltip>
            </IconButton>
           </Stack>
          ) : (
           <CircularProgress />
          )}
         </TableCell>
        </TableRow>
       )
      )}
     </TableBody>
    </Table>
   </TableContainer>
  );
 };

 return (
  <Stack>
   <Alert severity="info" sx={{ mb: 2, textAlign: "left" }} variant="outlined">
    <FormattedMessage id="zone.record.info" values={{ br: <br /> }} />
   </Alert>
   <Stack justifyContent="space-between" direction="row">
    <Stack>
     <Button
      variant="kxActionButton"
      color="success"
      disabled={editIndex !== -1 || deleteIndex !== -1}
      startIcon={<AddIcon />}
      onClick={() => AddNewRecord()}>
      <FormattedMessage id="domains.record.addRecord" />
     </Button>
    </Stack>
    <Stack>
     <TextField
      size="small"
      label="Search"
      inputRef={searchRef}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
       e.stopPropagation();
      }}
      InputLabelProps={{ shrink: true }}
      InputProps={{ endAdornment: <SearchIcon color="disabled" /> }}
      onChange={({ currentTarget }) => handleSearchZone(currentTarget.value)}
     />
    </Stack>
   </Stack>
   <Divider sx={{ my: 2 }} />
   {currentList?.length ? <RenderRecordList /> : <RenderEmptyState />}
   <TablePagination
    component="div"
    count={data.length}
    page={currentPage}
    rowsPerPage={10}
    onPageChange={handleOnPageChange}
    rowsPerPageOptions={[10]}
   />
  </Stack>
 );
};

export default ZoneRecords;
